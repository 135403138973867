import React, { useEffect, useRef } from 'react'
import { Outlet, useMatch } from 'react-router'
import styled, { css } from 'styled-components'

import { breakpoint } from '../../utils/breakpoint'

export const NavRoute: React.FC = () => {
  const matchRoot = useMatch('/')
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (containerRef.current) {
      // List of possible focusable elements
      const focusableSelectors = `
        a[href],
        button:not([disabled]),
        input:not([disabled]),
        select:not([disabled]),
        textarea:not([disabled]),
        [tabindex]:not([tabindex="-1"]),
        [contenteditable="true"]
      `

      const firstFocusableElement = containerRef.current.querySelector<HTMLElement>(focusableSelectors)

      if (firstFocusableElement) {
        firstFocusableElement.focus()
      }
    }
  }, [containerRef])

  // if mainView
  if (matchRoot) {
    return (
      <StyledContainerMainView ref={containerRef}>
        <Outlet />
      </StyledContainerMainView>
    )
  }

  return (
    <StyledContainer ref={containerRef}>
      <Outlet />
    </StyledContainer>
  )
}

const OrgAdminSpacing = css`
  padding: ${({ theme }) => theme.spacing.space7}rem;

  @media ${breakpoint.mobile} {
    padding: ${({ theme }) => theme.spacing.space2}rem;
    min-width: 304px;
  }
`

const StyledContainer = styled.div(
  ({
    theme: {
      brand: {
        breakpoint: { xxl },
      },
    },
  }) => css`
    ${OrgAdminSpacing};
    display: flex;
    flex-direction: column;
    min-width: 468px;
    width: 100%;
    max-width: ${xxl};
    flex: 1 0 0;
  `
)

const StyledContainerMainView = styled.div(
  ({
    theme: {
      brand: {
        breakpoint: { xxl },
      },
    },
  }) => css`
    ${OrgAdminSpacing};
    max-width: ${xxl};
    display: flex;
    flex-direction: column;
    min-width: 468px;
    width: 100%;
    min-height: 100%;
    flex: 1 0 0;
  `
)
