import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useMemo, useState } from 'react'
import styled from 'styled-components'

import { MobileInviteListItem, ShowMoreButton } from '../../components'
import { StoreContext } from '../../store'
import { IInvite } from '../../store/dataModels/interfaces'
import { ContentSection } from '../../style/layout'
import { checkIfMobile } from '../../utils/breakpoint'
import { InviteListItem } from '..'

interface IProps {
  invites: IInvite[]
}

export const InviteListComponent: React.FC<IProps> = ({ invites }) => {
  const [visibleInvites, setVisibleInvites] = useState(3)
  const {
    layoutStore: { isSmallMobile },
  } = useContext(StoreContext)

  const handleAddInvites = useCallback(() => {
    if (checkIfMobile()) {
      return setVisibleInvites(visibleInvites + 3)
    }

    return setVisibleInvites(visibleInvites + 20)
  }, [visibleInvites])

  const handleHideInvite = useCallback(() => {
    return setVisibleInvites(3)
  }, [])

  const renderShowButton = useMemo(() => {
    if (visibleInvites < invites.length) {
      return <ShowMoreButton id="show_more_invites_button" onClick={handleAddInvites} type="showMore" />
    }

    if (visibleInvites !== 3) {
      return <ShowMoreButton id="show_less_invites_button" onClick={handleHideInvite} type="showLess" />
    }
  }, [handleAddInvites, handleHideInvite, invites.length, visibleInvites])

  return useMemo(() => {
    if (!isSmallMobile) {
      return (
        <ContentSection>
          <StyledTable>
            <tbody>
              {invites.slice(0, visibleInvites).map((invite) => {
                return <InviteListItem key={invite.inviteId} invite={invite} />
              })}
            </tbody>
          </StyledTable>
          {renderShowButton}
        </ContentSection>
      )
    }

    return (
      <ContentSection>
        <div>
          {invites.slice(0, visibleInvites).map((invite) => (
            <MobileInviteListItem key={invite.inviteId} invite={invite} />
          ))}
        </div>
        {renderShowButton}
      </ContentSection>
    )
  }, [invites, isSmallMobile, renderShowButton, visibleInvites])
}

export const InviteList = observer(InviteListComponent)

const StyledTable = styled.table`
  width: 100%;
`
