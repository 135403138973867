import { observer } from 'mobx-react-lite'
import React, { FC, MouseEventHandler, useContext } from 'react'
import { useNavigate } from 'react-router'
import styled, { css } from 'styled-components'

import { StoreContext } from '../../../store'
import { EditButton } from '../../buttons/buttons'
import { RemoveUserModal } from '../../editUser/removeUserModal'

interface IEditRemoveProps {
  businessId: string
  accountId: string
  email: string
  inline?: boolean
}

const EditDeleteComponent: FC<IEditRemoveProps> = ({ businessId, accountId, email, inline }) => {
  const navigate = useNavigate()
  const { userDetailsStore } = useContext(StoreContext)

  const goToEdit: MouseEventHandler<HTMLButtonElement> = (event) => {
    navigate(`/edit-user/${userDetailsStore.userId}/${event.currentTarget.value}`)
  }

  return (
    <ButtonLayout inline={inline}>
      <EditButton value={businessId} onClick={goToEdit} id={`edit_button_${businessId}`} />
      <RemoveUserModal businessId={businessId} accountId={accountId} userEmail={email} />
    </ButtonLayout>
  )
}

export const EditRemove = observer(EditDeleteComponent)

const ButtonLayout = styled('div')<{ inline?: boolean }>(
  ({ theme: { spacing }, inline }) => css`
    display: flex;
    flex-direction: ${inline ? 'row' : 'column'};
    gap: ${spacing.space1};
  `
)
