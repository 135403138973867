import { RadioGroup } from '@postidigital/posti-components/'
import { Radio } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface IProps {
  isMainUser: boolean
  disabled?: boolean
  toggleUserRole?: () => any
  horizontal?: boolean
  plurarTranslations?: boolean
  small?: boolean
}

const options = {
  en: [
    { id: 'mainuser_radio_mainuser', label: 'Main User', value: 'MainUser' },
    { id: 'mainuser_radio_user', label: 'User', value: 'User' },
  ],
  fi: [
    { id: 'mainuser_radio_mainuser', label: 'Pääkäyttäjä', value: 'MainUser' },
    { id: 'mainuser_radio_user', label: 'Käyttäjä', value: 'User' },
  ],
  sv: [
    { id: 'mainuser_radio_mainuser', label: 'Huvudanvändare', value: 'MainUser' },
    { id: 'mainuser_radio_user', label: 'Användare', value: 'User' },
  ],
}

const optionsPlurar = {
  en: [
    { id: 'mainuser_radio_user', label: 'All Users', value: 'User' },
    { id: 'mainuser_radio_mainuser', label: 'Main Users', value: 'MainUser' },
  ],
  fi: [
    { id: 'mainuser_radio_user', label: 'Kaikki Käyttäjät', value: 'User' },
    { id: 'mainuser_radio_mainuser', label: 'Pääkäyttäjät', value: 'MainUser' },
  ],
  sv: [
    { id: 'mainuser_radio_user', label: 'Alla Användare', value: 'User' },
    { id: 'mainuser_radio_mainuser', label: 'Huvudanvändare', value: 'MainUser' },
  ],
}

const MainUserRadioGroupComponent: React.FC<IProps> = ({
  isMainUser,
  toggleUserRole,
  disabled,
  horizontal,
  plurarTranslations,
  small,
}) => {
  const { i18n } = useTranslation()
  const curLang = i18n.language

  const selectedUserRole = useMemo(() => {
    if (!plurarTranslations) {
      return isMainUser ? options[curLang][0] : options[curLang][1]
    }

    return !isMainUser ? optionsPlurar[curLang][0] : optionsPlurar[curLang][1]
  }, [curLang, isMainUser, plurarTranslations])

  const radioButtons = useMemo(() => {
    const opt = plurarTranslations ? optionsPlurar : options

    return opt[curLang].map((option) => (
      <Radio
        key={option.id}
        label={option.label}
        value={option.value}
        disabled={disabled}
        checked={option.value === selectedUserRole.value}
        labelPosition="right"
        name="radio-group-horizontal"
        size={small ? 'small' : 'default'}
        onChange={toggleUserRole}
      />
    ))
  }, [curLang, disabled, plurarTranslations, selectedUserRole.value, small, toggleUserRole])

  return (
    <RadioGroup isBrand alignedHorizontally={horizontal} onChange={toggleUserRole} value={selectedUserRole.value}>
      {radioButtons}
    </RadioGroup>
  )
}

export const MainUserRadioGroup = observer(MainUserRadioGroupComponent)
