export enum Routes {
  EMPTY = '/:locale/oneaccount-orgadmin-front',
}

/**
 * Global identifiers
 */
export enum ID {
  OMAPOSTI = 'omaposti',
  // Change this to the feature id of your feature. Also edit the id in public/index.html to match this
  FEATURE = 'oneaccount-orgadmin-front',
}
