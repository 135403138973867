import React from 'react'

import { authStore } from './auth.store'
import { environmentStore } from './environmentStore'
import { inviteDetailsStore } from './inviteDetailsStore'
import { inviteStore } from './inviteUserStore'
import { layoutStore } from './layoutStore'
import { organisationStore } from './organisationStore'
import { searchStore } from './searchStore'
import { userDetailsStore } from './userDetailsStore'
import { userStore } from './userStore'

export const StoreContext = React.createContext({
  authStore,
  layoutStore,
  userStore,
  inviteStore,
  organisationStore,
  searchStore,
  inviteDetailsStore,
  userDetailsStore,
  environmentStore,
})

export * from './auth.store'
export * from './environmentStore'
export * from './inviteDetailsStore'
export * from './inviteUserStore'
export * from './layoutStore'
export * from './organisationStore'
export * from './searchStore'
export * from './userDetailsStore'
export * from './userStore'
