import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { SubTitle, ViewLoading } from '../../components'
import { StoreContext } from '../../store'
import { ContentSection } from '../../style/layout'
import { getOrgUserPath } from './OrganizationUtil'
import { WideCard } from './WideCard'

export const UserDetailsComponent: React.FC = () => {
  const { organisationStore } = useContext(StoreContext)
  const { mainUsers, normalUsers, isLoading } = organisationStore

  const { t } = useTranslation()

  if (isLoading) {
    return <ViewLoading />
  }

  const renderUsers = (mainUsers) => {
    return mainUsers.map((user) => {
      return (
        <WideCard
          to={getOrgUserPath(user.organizationUserId)}
          key={user.organizationUserId}
          title={`${user.firstName} ${user.lastName}`}
          subtitle={user.email}
        />
      )
    })
  }

  return (
    <ContentSection>
      <ContentSection>
        <SubTitle>{t(`general.mainUsers`)}</SubTitle>
        <div>{renderUsers(mainUsers)}</div>
      </ContentSection>
      <ContentSection>
        <SubTitle>{t(`general.users`)}</SubTitle>
        <div>{renderUsers(normalUsers)}</div>
      </ContentSection>
    </ContentSection>
  )
}

export const UserDetails = observer(UserDetailsComponent)
