import { Button } from '@postidigital/posti-components/build/brand'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import ErrorLarge from '../../static/img/error_large'

enum Errors {
  general = 'general',
  badUrl = 'badUrl',
}

interface IProps {
  error: keyof typeof Errors
}

export const ErrorComponentComponent: React.FC<IProps> = ({ error }) => {
  const { t } = useTranslation()

  return (
    <StyledErrorContainer>
      <ErrorLarge />
      <StyledTitle>{t(`errors.${error}.title`)}</StyledTitle>
      {error === 'badUrl' && <StyledBodyText>{t(`errors.${error}.body`)}</StyledBodyText>}
      <StyledButton
        css
        title={t(`errors.reload`)}
        onClick={() => {
          location.href = '/'
        }}
      >
        {t(`errors.refresh`)}
      </StyledButton>
    </StyledErrorContainer>
  )
}

export const ErrorComponent = ErrorComponentComponent

const StyledButton = styled(Button)`
  margin-top: 32px;
`

const StyledErrorContainer = styled.div(
  () => css`
    height: 100%;
    padding: 0;
    margin: 48px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `
)

const StyledTitle = styled.div(
  () => css`
    font-family: PostiSans sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 39px;
    line-height: 40px;
    letter-spacing: -0.01em;

    color: #000000;
    margin-top: 16px;

    text-align: center;
  `
)

const StyledBodyText = styled.div(
  ({ theme }) => css`
    font-family: ${theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    text-align: center;
    margin-top: 8px;
  `
)
