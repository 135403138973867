import i18n, { Locale } from '../utils/i18n'

import english from './en'
import finnish from './fi'
import swedish from './sv'

export function initLocales(): void {
  const NAMESPACE = 'AddressBook'

  const translations = {
    [Locale.FI]: {
      [NAMESPACE]: finnish,
    },
    [Locale.EN]: {
      [NAMESPACE]: english,
    },
    [Locale.SV]: {
      [NAMESPACE]: swedish,
    },
  }

  i18n.init(translations, NAMESPACE)
}
