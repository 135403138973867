import styled, { css } from 'styled-components'

import { DropDownType, IDropDownOption, INumberOption } from '../store/dataModels/interfaces'
import { breakpoint } from './breakpoint'

/**
 * Show something only in desktop breakpoint
 */
export const OnlyInDesktop = styled('div')(
  () => css`
    display: none;
    @media ${breakpoint.md} {
      display: flex;
      flex-shrink: 0;
    }
  `
)

export const arrayIsEmpty = (array: Array<any>): boolean => {
  return Array.isArray(array) && array.length === 0
}

export const toDropDownOptions = (
  array: string[],
  options: IDropDownOption[],
  allOption?: IDropDownOption
): IDropDownOption[] => {
  return array.map((value) => {
    if (value === allOption?.value) {
      return allOption
    }

    // Use option's label text
    let label = value
    let type: DropDownType
    if (options?.length) {
      const foundOption = options.find((v) => v.value === value)

      label = foundOption?.label || value
      type = foundOption?.type || undefined
    }

    return { value, label, type }
  })
}

export const toStringArray = (array: IDropDownOption[]): string[] => {
  return array.map((obj) => {
    return obj.value
  })
}

export const getCurrentBasePath = (): string => {
  const { basename, locale } = document.getElementById('oneaccount-orgadmin-front').dataset
  if (!basename) return ''
  return basename.replace(':locale', locale)
}

export const addValuesToLabels = (numbers: INumberOption[], type: DropDownType): IDropDownOption[] =>
  numbers.map(
    (number: INumberOption): IDropDownOption => ({
      value: number.value,
      label: `${number.value} ${number.label}`,
      isDisabled: number.disabled,
      type: type,
    })
  )

export const sortIDropDownOptionsByDisabledStatusAndValue = (
  a: IDropDownOption | null | undefined,
  b: IDropDownOption | null | undefined
): number => {
  if (a == null && b != null) return 1
  if (a != null && b == null) return -1
  if (a == null && b == null) return 0

  if (a.isDisabled && !b.isDisabled) return 1
  if (!a.isDisabled && b.isDisabled) return -1

  if (a.value == null && b.value != null) return 1
  if (a.value != null && b.value == null) return -1
  if (a.value == null && b.value == null) return 0

  const aNumber = parseInt(a.value, 10)
  const bNumber = parseInt(b.value, 10)

  if (isFinite(aNumber) && isFinite(bNumber)) {
    return aNumber - bNumber
  }

  return a!.value.localeCompare(b!.value)
}
