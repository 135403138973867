import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { MainTitle, ViewLoading, WideCard } from '../components'
import { StoreContext } from '../store'
import { ContentSection } from '../style/layout'

const OrganisationsComponent: React.FC = () => {
  const { t } = useTranslation()

  const { userStore } = useContext(StoreContext)
  const { user } = userStore

  if (userStore.isLoading) {
    return <ViewLoading />
  }

  const getOrgPath = (businessId: string): string => {
    return `/organisation/${businessId}`
  }

  return (
    <ContentSection>
      <MainTitle>{t(`general.organisations`)}</MainTitle>
      <div>
        {user.organizations.map(({ name, businessId }) => {
          return <WideCard title={`${name} (${businessId})`} key={businessId} to={getOrgPath(businessId)} />
        })}
      </div>
    </ContentSection>
  )
}

export const OrganisationsView = observer(OrganisationsComponent)
