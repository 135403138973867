import styled, { css } from 'styled-components'

type SpacingType = 'sm' | 'md' | 'lg' | 'xl'

export const ContentSection = styled('div')<{ gap?: SpacingType; top?: SpacingType }>(({
  theme: { spacing },
  gap = 'md',
  top,
}) => {
  const space: Record<typeof gap, number> = {
    sm: spacing.space2,
    md: spacing.space4,
    lg: spacing.space7,
    xl: spacing.space10,
  }

  return css`
    display: flex;
    flex-direction: column;
    justify-content: start;
    ${() => gap && `gap: ${space[gap]}rem;`}
    ${() => top && `padding-top: ${space[top]}rem;`}
  `
})
