import { Headline } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface IProps {
  pendingRequest: string
  type: 'invite' | 'editUser'
}

const FetchingTitleComponent: React.FC<IProps> = ({ pendingRequest, type }) => {
  const [t] = useTranslation()

  const renderTitleText = (pendingRequest: string): string => {
    switch (pendingRequest) {
      case 'loading':
        return type === 'invite' ? t(`invite.sending`) : t(`editUser.sending`)
      case 'success':
        return type === 'invite' ? t(`invite.success`) : t(`editUser.success`)
      case 'error':
        return type === 'invite' ? t(`invite.error`) : t(`editUser.error`)
    }
  }

  return (
    <StyledHeadline as="div" size="Four" id="fetch_response_text">
      {renderTitleText(pendingRequest)}
    </StyledHeadline>
  )
}

export const FetchingTitle = observer(FetchingTitleComponent)

const StyledHeadline = styled(Headline)`
  margin-top: 24px;
`
