import { Notification } from '@postidigital/posti-components'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { StoreContext } from '../../store'

const InviteNotificationComponent: React.FC = () => {
  const { inviteStore } = useContext(StoreContext)
  const { t } = useTranslation()
  const { pendingRequest } = inviteStore

  return (
    <StyledNotiWrapper>
      <Notification contentType={getNotiType(pendingRequest)}>
        <StyledSpan>{t(generateText(pendingRequest))}</StyledSpan>
      </Notification>
    </StyledNotiWrapper>
  )
}

export const InviteNotification = observer(InviteNotificationComponent)

const getNotiType = (pendingRequest: string) => {
  switch (pendingRequest) {
    case 'success':
      return 'success'
    case 'error':
      return 'alert'
    default:
      return 'warning'
  }
}

const generateText = (pendingRequest: string) => {
  switch (pendingRequest) {
    case 'success':
      return 'invite.success'
    case 'error':
      return 'errors.general.title'
    case 'loading':
      return 'general.loading'
    default:
      return 'invite.rolesRequired'
  }
}

const StyledNotiWrapper = styled.span(
  () => css`
    margin-bottom: 1.5rem;
  `
)

const StyledSpan = styled.span(
  () => css`
    margin-left: 3rem;
  `
)
