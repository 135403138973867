import fetch from 'isomorphic-fetch'
import { v4 as uuid } from 'uuid'

import { authStore } from '../store'

class ServiceAdapter {
  async createDefaultHeaders() {
    const accessToken = authStore.getAccessToken()

    const requestId = uuid()

    const xsrfToken = ('; ' + document.cookie).split('; XSRF-TOKEN=').pop().split(';').shift()

    return {
      'Content-Type': 'application/json',
      'X-XSRF-TOKEN': xsrfToken,
      Authorization: `Bearer ${accessToken}`,
      RequestId: requestId,
    }
  }

  endpointURL: string = ``

  setEndpointURL = (hostname: string) => {
    if (hostname.includes('localhost')) {
      // process.env.API_ENDPOINT defaults to empty string
      // but you can pass it manually if you want
      // used for local dev
      this.endpointURL = process.env.API_ENDPOINT
      return
    }
    if (hostname.includes('dev')) {
      this.endpointURL = 'https://orgadmin.dev.account.posticloud.fi'
      return
    }
    if (hostname.includes('test') || hostname.includes('tst')) {
      this.endpointURL = 'https://orgadmin.tst.account.posticloud.fi'
      return
    }
    if (hostname.includes('staging') || hostname.includes('stg')) {
      this.endpointURL = 'https://orgadmin.stg.account.posticloud.fi'
      return
    }
    // else fetch from production url
    this.endpointURL = `https://organizations.account.posti.fi`
  }

  addEndpointToUrl(url: string): string {
    return `${this.endpointURL}${url}`
  }

  async sendGetRequest(url: string): Promise<Response> {
    const defaultHeaders = await this.createDefaultHeaders()

    return fetch(this.addEndpointToUrl(url), {
      method: 'GET',
      credentials: 'same-origin',
      headers: defaultHeaders,
    })
  }

  async sendPostRequest(url: string, body: any): Promise<Response> {
    const defaultHeaders = await this.createDefaultHeaders()
    return fetch(this.addEndpointToUrl(url), {
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(body),
      headers: defaultHeaders,
    })
  }

  async sendPatchRequest(url: string, body: any): Promise<Response> {
    const defaultHeaders = await this.createDefaultHeaders()
    return fetch(this.addEndpointToUrl(url), {
      method: 'PATCH',
      credentials: 'same-origin',
      body: JSON.stringify(body),
      headers: defaultHeaders,
    })
  }

  async sendDeleteRequest(url: string): Promise<Response> {
    const defaultHeaders = await this.createDefaultHeaders()
    return fetch(this.addEndpointToUrl(url), {
      method: 'DELETE',
      credentials: 'same-origin',
      headers: defaultHeaders,
    })
  }
}

export const serviceAdapter = new ServiceAdapter()
