import { action, makeObservable, observable, runInAction } from 'mobx'

const SMALL_MOBILE_BREAKPOINT_WIDTH_PX = 480
const DESKTOP_BREAKPOINT_WIDTH_PX = 768

/**
 * Store to handle layout related changes
 */
export class LayoutStore {
  private windowWidth: number = this.screenDimensions.width

  isSmallMobile: boolean = this.windowWidth <= SMALL_MOBILE_BREAKPOINT_WIDTH_PX
  isMobile: boolean = this.windowWidth <= DESKTOP_BREAKPOINT_WIDTH_PX
  isDesktop: boolean = this.windowWidth > DESKTOP_BREAKPOINT_WIDTH_PX

  get screenDimensions() {
    const {
      outerWidth,
      innerWidth,
      outerHeight,
      innerHeight,
      screen: { availHeight, availWidth },
    } = window

    // AvailWidth & availHeight will have mixed values in multi-screen setups.
    // They work properly for most mobile clients however.
    return {
      width: (availWidth > innerWidth ? innerWidth : availWidth) || outerWidth,
      height: (availHeight > innerHeight ? innerHeight : availHeight) || outerHeight,
    }
  }

  constructor() {
    makeObservable(this, {
      isMobile: observable,
      isDesktop: observable,
      isSmallMobile: observable,
      setWindowWidth: action,
    })

    const setDimensions = () => {
      const { width } = this.screenDimensions
      this.setWindowWidth(width)
    }

    const smallMobileMediaQuery: MediaQueryList = window.matchMedia(
      `(max-width: ${SMALL_MOBILE_BREAKPOINT_WIDTH_PX}px)`
    )
    const mediaQueryList: MediaQueryList = window.matchMedia(`(max-width: ${DESKTOP_BREAKPOINT_WIDTH_PX}px)`)

    smallMobileMediaQuery.addEventListener('change', (event) => {
      runInAction(() => {
        this.isSmallMobile = event.matches
      })
    })

    mediaQueryList.addEventListener('change', (event) => {
      runInAction(() => {
        if (event.matches) {
          this.isMobile = true
          this.isDesktop = false
        } else {
          this.isMobile = false
          this.isDesktop = true
        }
      })
    })

    window.onresize = debounce(setDimensions, 250, false)
  }

  setWindowWidth = (width: number) => {
    this.windowWidth = width
  }
}

export const layoutStore = new LayoutStore()

export function debounce(func, wait, immediate) {
  let timeout
  return function (...args) {
    const later = function () {
      timeout = null
      if (!immediate) func.apply(this, args)
    }
    const callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(this, args)
  }
}
