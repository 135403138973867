import { Headline } from '@postidigital/posti-components/build/brand'
import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router'
import styled from 'styled-components'

import {
  BackButton,
  InfoBox,
  InviteDropDown,
  NextButton,
  RoleContractRequirements,
  Stepper,
  SubTitle,
} from '../../components'
import { StoreContext } from '../../store'
import { DropDownType } from '../../store/dataModels/interfaces'
import { ContentSection } from '../../style/layout'
import { arrayIsEmpty, sortIDropDownOptionsByDisabledStatusAndValue } from '../../utils/helpers'

const InviteStep3Component: React.FC = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    sendPageInfo({
      language: i18n.language,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/invite/3`,
      pageName: 'oneaccount-orgadmin-invite',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { inviteStore, userStore, environmentStore } = useContext(StoreContext)

  const {
    customerNumbers,
    emailList,
    setSelectedCustomerNumbers,
    selectedCustomerNumbers,
    LSCNumbers,
    selectedLSCNumbers,
    setSelectedLSCNumbers,
    transportIds,
    selectedTransportIds,
    setSelectedTransportIds,
    businessPartnerNumbers,
    selectedBusinessPartnerNumbers,
    setSelectedBusinessPartnerNumbers,
    roleValues,
    selectedBusiness,
    pendingRequest,
    isLoading,
  } = inviteStore

  const getCustomerNumbers = () => {
    return businessPartnerNumbers
      .slice()
      .sort(sortIDropDownOptionsByDisabledStatusAndValue)
      .concat(customerNumbers.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue))
  }

  const selectCustomerNumbers = (values) => {
    setSelectedCustomerNumbers(values.filter((number) => number.type === DropDownType.CUSTOMER_NUMBER))
    setSelectedBusinessPartnerNumbers(values.filter((number) => number.type === DropDownType.BUSINESS_PARTNER_NUMBER))
  }

  const getSelectedCustomerNumbers = () => {
    return selectedBusinessPartnerNumbers
      .slice()
      .sort(sortIDropDownOptionsByDisabledStatusAndValue)
      .concat(selectedCustomerNumbers.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue))
  }

  const sendInvitePossible = useMemo((): boolean => {
    // Send not possible if old request is still pending
    if (isLoading) {
      return false
    }
    if (!customerNumbers && !LSCNumbers && !transportIds && !businessPartnerNumbers) {
      return true
    }

    const roles = userStore.getRolesThatRequireContracts(selectedBusiness.businessId, roleValues)

    const selectedTypes = []
    if (!arrayIsEmpty(selectedCustomerNumbers.slice())) {
      selectedTypes.push(DropDownType.CUSTOMER_NUMBER)
    }
    if (!arrayIsEmpty(selectedLSCNumbers.slice())) {
      selectedTypes.push(DropDownType.LOGISTICS_CONTRACT_NUMBER)
    }
    if (!arrayIsEmpty(selectedTransportIds.slice())) {
      selectedTypes.push(DropDownType.TRANSPORT_ID)
    }
    if (!arrayIsEmpty(selectedBusinessPartnerNumbers.slice())) {
      selectedTypes.push(DropDownType.BUSINESS_PARTNER_NUMBER)
    }

    let requirementsOk = true
    roles.forEach((role) => {
      const requiredTypes = []
      if (role.customerNumberRequired) {
        requiredTypes.push(DropDownType.CUSTOMER_NUMBER)
      }
      if (role.logisticsContractNumberRequired) {
        requiredTypes.push(DropDownType.LOGISTICS_CONTRACT_NUMBER)
      }
      if (role.transportIdRequired) {
        requiredTypes.push(DropDownType.TRANSPORT_ID)
      }
      if (role.businessPartnerNumberRequired) {
        requiredTypes.push(DropDownType.BUSINESS_PARTNER_NUMBER)
      }

      const intersection = requiredTypes.filter((item) => selectedTypes.indexOf(item) !== -1)
      if (intersection.length === 0) {
        requirementsOk = false
        return
      }
    })

    return requirementsOk
  }, [
    LSCNumbers,
    businessPartnerNumbers,
    customerNumbers,
    isLoading,
    roleValues,
    selectedBusiness.businessId,
    selectedBusinessPartnerNumbers,
    selectedCustomerNumbers,
    selectedLSCNumbers,
    selectedTransportIds,
    transportIds,
    userStore,
  ])

  const handleNextClick = useCallback(() => {
    inviteStore.postInviteToBackend(i18n.language)
    navigate('/invite/4')
  }, [i18n, inviteStore, navigate])

  const handleBackClick = useCallback(() => {
    inviteStore.clearPendingRequest()
    inviteStore.clearStep3State()
    navigate('/invite/2')
  }, [inviteStore, navigate])

  const contractNumbersExist = useMemo(
    () =>
      !![customerNumbers?.length, LSCNumbers?.length, transportIds?.length, businessPartnerNumbers?.length].some(
        (length) => length > 0
      ),
    [LSCNumbers, businessPartnerNumbers, customerNumbers, transportIds]
  )

  // Redirect user to beginning of flow if emails or selectedBusiness have not been set
  if (pendingRequest === '' && (emailList.length < 1 || !selectedBusiness.businessId)) {
    return <Navigate to="/invite/1" />
  }

  return (
    <ContentSection gap="lg">
      <Stepper />
      <InfoBox />

      {environmentStore.hasFeature('transportId') &&
        (customerNumbers || LSCNumbers || transportIds || businessPartnerNumbers) && (
          <SubTitle>{t(`invite.customerNumbersContracts`)}</SubTitle>
        )}
      {!environmentStore.hasFeature('transportId') &&
        (customerNumbers || LSCNumbers || transportIds || businessPartnerNumbers) && (
          <SubTitle>{t(`invite.customerNumbersContractsOld`)}</SubTitle>
        )}

      <RoleContractRequirements businessId={selectedBusiness.businessId} roleValues={roleValues} />

      <ContentSection>
        {contractNumbersExist && (
          <Headline as="h3" size="Six">
            {t(`general.contractNumbers`)}
          </Headline>
        )}

        {((customerNumbers && customerNumbers.length > 0) ||
          (businessPartnerNumbers && businessPartnerNumbers.length > 0)) && (
          <InviteDropDown
            id="customer_number_dropdown"
            title={t(`general.selectCustomerNumbers`)}
            placeHolderText={t(`general.customerNumbers`)}
            options={getCustomerNumbers()}
            selectedOptions={getSelectedCustomerNumbers()}
            onChange={selectCustomerNumbers}
          />
        )}
        {LSCNumbers && LSCNumbers.length > 0 && (
          <InviteDropDown
            id="lsc_number_dropdown"
            title={t(`general.selectLogisticsContractNumbers`)}
            placeHolderText={t(`general.LCSNumbers`)}
            options={LSCNumbers?.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue)}
            selectedOptions={selectedLSCNumbers}
            onChange={setSelectedLSCNumbers}
          />
        )}
        {transportIds && transportIds.length > 0 && (
          <InviteDropDown
            id="transport_id_dropdown"
            title={t(`general.selectTransportIds`)}
            placeHolderText={t(`general.transportIds`)}
            options={transportIds?.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue)}
            selectedOptions={selectedTransportIds}
            onChange={setSelectedTransportIds}
          />
        )}
      </ContentSection>
      <StyledButtonsWrapper>
        <BackButton onClick={handleBackClick} />

        <NextButton disabled={!sendInvitePossible} customText={t(`invite.send`)} onClick={handleNextClick} />
      </StyledButtonsWrapper>
    </ContentSection>
  )
}

export const InviteStep3 = observer(InviteStep3Component)

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`
