import React from 'react'
import ReactDOM from 'react-dom/client'

import { ID } from './constants'
import IndexWrapper from './views/IndexWrapper'

import 'core-js/es/set'
import 'core-js/es/map'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'raf/polyfill'

const rootDomElement = document.getElementById(ID.FEATURE)

export const root = rootDomElement ? ReactDOM.createRoot(rootDomElement) : null

root?.render(<IndexWrapper />)
