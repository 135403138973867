import { BrandTheme } from '@postidigital/posti-theme'

export const breakpoint = {
  xs: `(min-width: ${BrandTheme.breakpoint.xs})`,
  sm: `(min-width: ${BrandTheme.breakpoint.sm})`,
  md: `(min-width: ${BrandTheme.breakpoint.md})`,
  lg: `(min-width: ${BrandTheme.breakpoint.lg})`,
  mobile: `(min-width: ${BrandTheme.breakpoint.xs}) and (max-width: 767px)`,
}

export function checkIfMobile(): boolean {
  return /Mobi|Android/i.test(navigator.userAgent)
}
