import { sendPageInfo, sendUserAction } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import styled from 'styled-components'

import { FetchingAnimation, FetchingTitle } from '../../components'
import { StoreContext } from '../../store'
import { IUserDetailOrganisation } from '../../store/dataModels/interfaces'
import { ContentSection } from '../../style/layout'
import i18n from '../../utils/i18n'
import { DoneButton } from '../buttons/buttons'
import { CSATSelector } from '../general/CSATSelector'

interface IProp {
  organisationData: IUserDetailOrganisation
}

const EditStep3Component: React.FC<IProp> = ({
  organisationData: {
    organizationUser: { selectedRoles },
  },
}) => {
  const navigate = useNavigate()
  const { userDetailsStore } = useContext(StoreContext)
  const { pendingRequest } = userDetailsStore
  const { orgid, userid } = useParams()

  useEffect(() => {
    sendPageInfo({
      language: i18n.getLocale,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/edit-user/${userid}/${orgid}/3`,
      pageName: 'oneaccount-orgadmin-edit-users',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (pendingRequest === 'success' || pendingRequest === 'error') {
      sendUserAction({
        actionType: pendingRequest === 'success' ? 'editUserSuccess' : 'editUserError',
        businessId: orgid,
        userType: selectedRoles.includes('MainUser') ? 'main user' : 'user',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingRequest])

  const handleDone = useCallback(() => {
    navigate(-1)
  }, [navigate])

  return (
    <ContentSection gap="lg">
      <FetchingTitle pendingRequest={pendingRequest} type="editUser" />
      <StyledCentered>
        <FetchingAnimation pendingRequest={pendingRequest} />
        {pendingRequest === 'success' && <CSATSelector />}
      </StyledCentered>
      <StyledButtonContainer>
        <DoneButton onClick={handleDone} />
      </StyledButtonContainer>
    </ContentSection>
  )
}

export const EditStep3 = observer(EditStep3Component)

const StyledCentered = styled(ContentSection)`
  justify-content: center;
  align-items: center;
  width: 100%;
`

const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  margin-bottom: 24px;
`
