import { ListItemSection } from '@postidigital/posti-components'
import { BusinessIcon, Label } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { MouseEventHandler, useCallback, useContext, useEffect } from 'react'

import { StoreContext } from '../../store'
import { ContentSection } from '../../style/layout'
import { ViewLoading } from '../general/ViewLoading'

export const SelectOrganisationComponent: React.FC = () => {
  const { userStore, inviteStore } = useContext(StoreContext)
  const { user } = userStore
  const { organizations } = user || {}

  const { setSelectedBusiness, selectedBusiness } = inviteStore

  useEffect(() => {
    if (organizations && organizations.length == 1) {
      const name = organizations[0].name
      const businessId = organizations[0].businessId
      setSelectedBusiness({ name, businessId })
    }
  }, [organizations, setSelectedBusiness])

  const selectBusiness: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      const id = event.currentTarget?.value
      const { name, businessId } = organizations.find(({ businessId }) => businessId === id)

      if (!businessId) {
        return null
      }

      setSelectedBusiness({ name, businessId })
    },
    [organizations, setSelectedBusiness]
  )

  if (userStore.isLoading) {
    return <ViewLoading />
  }

  return (
    <ContentSection gap="md">
      {organizations.map(({ businessId, name }) => {
        const isSelected = businessId === selectedBusiness.businessId || organizations.length == 1

        return (
          <ListItemSection
            key={businessId}
            selected={isSelected}
            value={businessId}
            onClick={selectBusiness}
            aria-pressed={isSelected}
            icon={BusinessIcon}
          >
            <Label size="Three">{name}</Label>
          </ListItemSection>
        )
      })}
    </ContentSection>
  )
}

export const SelectOrganisationList = observer(SelectOrganisationComponent)
