import { Body, Headline, Notification } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { StoreContext } from '../../store'

interface IProps {
  businessId: string
  roleValues: string[]
}

const RoleContractRequirementsComponent: React.FC<IProps> = ({ businessId, roleValues }) => {
  const { t, i18n } = useTranslation()

  const { userStore, environmentStore } = useContext(StoreContext)

  const transportIdEnabled = environmentStore.hasFeature('transportId')

  const requirements = useMemo(
    () =>
      userStore
        .getRolesThatRequireContracts(businessId, roleValues)
        .map((role) => {
          const requirements = [
            ['general.requiresCustomerNumbers', role.customerNumberRequired || role.businessPartnerNumberRequired],
            ['general.requiresLogisticsContractNumbers', role.logisticsContractNumberRequired],
          ]
          if (transportIdEnabled) {
            requirements.push(['general.requiresTransportIds', role.transportIdRequired])
          }
          let missingMessage = requirements
            .filter((item) => item[1])
            .map((item) => t(item[0]))
            .join(', ')
          missingMessage = missingMessage.replace(/,\s([^,]+)$/, ' ' + t('general.or') + ' $1')
          return role.name[i18n.language.toLowerCase()] + ' ' + t('general.requires') + ' ' + missingMessage + '.'
        })
        .map((str) => (
          <Body as="div" size="Five" key={str}>
            {str}
          </Body>
        )),
    [businessId, i18n.language, roleValues, t, transportIdEnabled, userStore]
  )

  return (
    requirements.length > 0 && (
      <Notification dismissible={false} contentType="informational">
        <Headline as="h3" size="Seven">
          {t(`general.missingContractNumber`)}
        </Headline>
        {requirements}
        <Body size="Five">
          {transportIdEnabled && t(`general.contractInfo`)}
          {!transportIdEnabled && t(`general.contractInfoOld`)}
        </Body>
      </Notification>
    )
  )
}

export const RoleContractRequirements = observer(RoleContractRequirementsComponent)
