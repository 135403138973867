import { Body } from '@postidigital/posti-components/build/brand'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Dropdown } from '../../components'
import { DropDownType, IDropDownOption } from '../../store/dataModels/interfaces'

interface IProps {
  id?: string
  title: string
  placeHolderText: string
  options?: IDropDownOption[]
  selectedOptions?: IDropDownOption[]
  onChange?: (values: IDropDownOption[]) => any
}

export const InviteDropDownComponent: React.FC<IProps> = ({
  id,
  title,
  placeHolderText,
  options,
  onChange,
  selectedOptions,
}) => {
  const [t] = useTranslation()

  // react select can not handle mobx observables, so we have to convert the array to JS
  const optionsToJs = toJS(options)
  const selectedOptionsToJs = toJS(selectedOptions)

  const allOption = {
    label: t(`general.selectAll`),
    value: '*',
    type: DropDownType.SELECT_ALL,
  }

  return (
    <StyledInputWrapper id={id || undefined}>
      <StyledLabelWrapper>
        {title && (
          <Body as="div" size="Three">
            {title}
          </Body>
        )}
      </StyledLabelWrapper>
      <Dropdown
        id={id}
        isMulti
        isSearchable
        allowSelectAll
        options={optionsToJs}
        allOption={allOption}
        closeMenuOnSelect={false}
        placeHolderText={placeHolderText}
        selectedOptions={selectedOptionsToJs}
        onChange={onChange}
      />
    </StyledInputWrapper>
  )
}

export const InviteDropDown = observer(InviteDropDownComponent)

const StyledInputWrapper = styled.div`
  position: relative;
  max-width: 360px;
`

const StyledLabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`
