import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import { serviceAdapter } from '../service/serviceAdapter'
import { IPostiEnv } from './dataModels/interfaces'

export class EnvironmentStore {
  postiEnv: IPostiEnv

  error: boolean = false

  constructor() {
    makeObservable(this, {
      postiEnv: observable,
      error: observable,
      fetchEnvironment: action,
      hasFeature: action,
      getEnv: computed,
    })
  }

  async fetchEnvironment() {
    try {
      const response = await serviceAdapter.sendGetRequest('/api/environment')

      if (response.status !== 200) {
        runInAction(() => {
          this.error = true
        })
        throw new Error('Bad response from server')
      }
      const env = await response.json()

      runInAction(() => {
        this.postiEnv = env
      })
    } catch (err) {
      console.error(err)
    }
  }

  hasFeature = (featureName: string) => {
    if (!this.postiEnv) {
      return false
    }

    if (!(typeof this.postiEnv.features === 'object' && this.postiEnv.features !== null)) {
      return false
    }

    return this.postiEnv.features[featureName]
  }

  get getEnv() {
    return this.postiEnv.env
  }
}

export const environmentStore = new EnvironmentStore()
