import { observer } from 'mobx-react-lite'
import React, { useCallback, useMemo } from 'react'
import { components } from 'react-select'
import styled, { css } from 'styled-components'

import AllSelect from '../../components/general/dropdown/AllSelect'
import { IDropDownOption, IGroupedDropDownOptions } from '../../store/dataModels/interfaces'

// If you are using mobx, be sure to covert your options to JS
// before passing as props to this component
// const optionsToJs = toJS(options)
// const selectedOptionsToJs = toJS(selectedOptions)
interface IProps {
  id?: string
  options: IDropDownOption[] | IGroupedDropDownOptions[]
  selectedOptions: IDropDownOption[]
  isMulti?: boolean
  placeHolderText?: string
  onChange?: (values: IDropDownOption[]) => any
  allowSelectAll?: boolean
  allOption: IDropDownOption // required, will brake if not passed
  // This disbles all options, you can still view them
  // do not pass a onChange function
  disabled?: boolean
  // if you want to disable a single option. You have to
  // pass the option with a disabled prop EG:
  // const options = [
  // [{label: "one", value: 1, disabled: true},
  // {label: "two", value: 2}]
  grouped?: boolean
  // the users has passed options in a group structure
}

const FilterDropdownComponent: React.FC<IProps> = ({
  id,
  options,
  onChange,
  selectedOptions,
  isMulti = false,
  placeHolderText = '',
  allowSelectAll = false,
  allOption,
  disabled,
  grouped,
}) => {
  const handleChange = (selectedOption) => {
    if (!selectedOption) {
      return onChange([])
    }

    if (Array.isArray(selectedOption)) {
      return onChange(selectedOption)
    }

    return onChange([selectedOption])
  }

  const formatOptionLabel = useCallback(
    ({ isDisabled, label, value }) => {
      return (
        <div key={`${id}-${value}`} style={isDisabled ? { color: 'rgb(194, 201, 209)' } : {}}>
          {label}
        </div>
      )
    },
    [id]
  )

  const label = useMemo(() => {
    let optionAmount = options.length

    if (grouped) {
      const flatOptions = options.reduce((accumulatedOptions, currentGroup) => {
        return accumulatedOptions.concat((currentGroup as IGroupedDropDownOptions).options)
      }, [])
      optionAmount = flatOptions.length
    }

    let selectedAmount = selectedOptions.length

    if (optionAmount <= selectedAmount) {
      selectedAmount = optionAmount
    }

    return selectedAmount ? `${placeHolderText} (${selectedAmount})` : placeHolderText
  }, [grouped, options, placeHolderText, selectedOptions.length])

  return (
    <StyledAllSelect
      id={id}
      isClearable
      isSearchable
      grouped={grouped}
      isDisabled={disabled}
      value={selectedOptions}
      onChange={handleChange}
      isMulti={isMulti}
      options={options}
      placeholder={label}
      components={{
        MultiValue,
        ValueContainer,
      }}
      noOptionsMessage={() => null}
      hideSelectedOptions={false}
      menuPlacement="auto"
      allowSelectAll={allowSelectAll}
      allOption={allOption}
      closeMenuOnSelect={false}
      formatOptionLabel={formatOptionLabel}
    />
  )
}

const MultiValue = () => null

const ValueContainer = ({ children, ...props }: any) => (
  <components.ValueContainer {...props}>
    {React.Children.map(children, (child) => (child && child.type !== components.Placeholder ? child : null))}
  </components.ValueContainer>
)

export const FilterDropdown = observer(FilterDropdownComponent)

const StyledAllSelect = styled(AllSelect)(
  ({ theme: { fontWeight, fontSize } }) => css`
    .select__control {
      label {
        position: absolute;
        top: calc(50% - 0.75rem);
        font-weight: ${fontWeight.semiBold};
        font-size: ${fontSize.label.four}rem;

        & + div {
          padding-top: 28px;
        }
      }

      &--is-focused label {
        top: 0.875rem;
        font-size: ${fontSize.body.five}rem;
        font-weight: ${fontWeight.normal};
      }
    }
  `
)
