import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router'
import styled from 'styled-components'

import { StoreContext } from '../../store'
import { ContentSection } from '../../style/layout'
import { AddAnotherButton, FetchingAnimation, FetchingTitle, ReadyButton, Stepper } from '..'
import { CSATSelector } from '../general/CSATSelector'

const InviteStep4Component: React.FC = () => {
  const { i18n } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    sendPageInfo({
      language: i18n.language,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/invite/4`,
      pageName: 'oneaccount-orgadmin-invite',
    })
    // On component unmount clear store
    return function cleanup() {
      inviteStore.clearStore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { inviteStore } = useContext(StoreContext)
  const { isSuccess, isLoading, pendingRequest } = inviteStore

  const handleReadyClick = useCallback(() => {
    inviteStore.clearStore()
    navigate('/')
  }, [inviteStore, navigate])

  const handleAnotherClick = useCallback(() => {
    inviteStore.clearStore()
    navigate('/invite/1')
  }, [inviteStore, navigate])

  if (pendingRequest === 'error') {
    return <Navigate to="/invite/failure" />
  }

  return (
    <ContentSection gap="lg">
      <Stepper />
      <FetchingTitle pendingRequest={pendingRequest} type="invite" />
      <StyledCentered>
        <FetchingAnimation pendingRequest={pendingRequest} />

        {isSuccess && <CSATSelector />}
      </StyledCentered>

      <StyledButtonsWrapper>
        <AddAnotherButton css disabled={isLoading} onClick={handleAnotherClick} />
        <ReadyButton css disabled={isLoading} onClick={handleReadyClick} />
      </StyledButtonsWrapper>
    </ContentSection>
  )
}

export const InviteStep4 = observer(InviteStep4Component)

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-top: auto;
  padding-top: 24px;
`

const StyledCentered = styled(ContentSection)`
  justify-content: center;
  align-items: center;
  width: 100%;
`
