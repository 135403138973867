import { PostcardIcon } from '@postidigital/posti-components/build/brand'
import { BrandTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'

import { IInvite } from '../../store/dataModels/interfaces'
import { formatDate, getInviteState } from '../../utils'

interface IProps {
  invite: IInvite
}

export const MobileInviteListItemComponent: React.FC<IProps> = ({ invite }) => {
  const { expired, email, expires, created, inviteId, cancelled, accepted } = invite
  const { t } = useTranslation()

  const getPostCardIconColor = (): string => {
    const pink = BrandTheme.color.surface.error.pressed
    const blue = BrandTheme.color.surface.success.pressed

    if (cancelled) {
      return pink
    }

    if (expired) {
      return pink
    }

    return blue
  }

  return (
    <StyledBox to={`/invite-detail/${inviteId}`}>
      <div>
        <PostcardIcon color={getPostCardIconColor()} fontSize="14px" width={'24px'} height={'24px'} />
      </div>

      <div>
        <StyledEmailText>{truncate(email, 30)}</StyledEmailText>
        <StyledNextToEachOther>
          <StyledCreatedText>{formatDate(created)}</StyledCreatedText>
          <StyledTimeExpired expired={expired}>
            {getInviteState(cancelled, expired, expires, accepted, t)}
          </StyledTimeExpired>
        </StyledNextToEachOther>
      </div>
    </StyledBox>
  )
}

export const MobileInviteListItem = observer(MobileInviteListItemComponent)

const StyledTimeExpired = styled.div<{ expired: boolean }>(
  ({ theme, expired }) => css`
    font-family: ${theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    color: ${expired ? theme.color.neutralPassiveGray : theme.color.neutralNetworkGray};
  `
)

const StyledNextToEachOther = styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    margin-top: 8px;
    align-items: center;
  `
)

const StyledEmailText = styled.div(
  ({ theme }) => css`
    font-family: ${theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    color: ${theme.color.neutralBlack};
    vertical-align: middle;
    word-break: break-all;

    padding-left: 8px;
    padding-top: 3px;
    padding-bottom: 8px;
  `
)

const StyledCreatedText = styled.div(
  ({ theme }) => css`
    font-family: ${theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    color: ${theme.color.neutralNetworkGray};
    padding-left: 8px;
    width: 120px;
  `
)

const StyledBox = styled(Link)(
  () => css`
    display: flex;
    flex-direction: row;
    cursor: pointer;
    flex-grow: 3;
    height: 84px;
    background: #ffffff;
    padding-left: 16px;
    margin-bottom: 16px;
  `
)

function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + '...' : str
}
