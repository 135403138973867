import { GlobalStyleNormalization } from '@postidigital/posti-components'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { I18nextProvider } from 'react-i18next'
import { Route, Routes } from 'react-router'
import { BrowserRouter } from 'react-router-dom'

import {
  ErrorBoundary,
  ErrorComponent,
  InviteFailure,
  InviteStep2,
  InviteStep3,
  InviteStep4,
  ViewLoading,
} from '../components'
import { NavRoute } from '../components/NavRoute'
import { StoreContext } from '../store'
import { i18nInstance } from '../utils'
import { GlobalStyle } from '../utils/ThemeWrapper'
import { EditUserView } from './editUserView'
import { InviteDetailsView } from './inviteDetailsView'
import { InviteView } from './inviteView'
import { MainView } from './mainView'
import { OrganisationsView } from './organisationsView'
import { SingleOrgView } from './singleOrgView'
import { UserDetailView } from './userDetailView'

export const IndexComponent: React.FC<{
  basename: string
}> = ({ basename }) => {
  const { userStore, searchStore } = useContext(StoreContext)

  if (!searchStore.firstLoadDone) {
    return <ViewLoading />
  }

  const renderContent = () => {
    if (userStore.error) {
      return <ErrorComponent error="general" />
    }

    return (
      <Routes>
        <Route path="/" element={<NavRoute />}>
          <Route path="/" element={<MainView />} />
          <Route path="/invite/1" element={<InviteView />} />
          <Route path="/invite/2" element={<InviteStep2 />} />
          <Route path="/invite/3" element={<InviteStep3 />} />
          <Route path="/invite/4" element={<InviteStep4 />} />
          <Route path="/invite/failure" element={<InviteFailure />} />
          <Route path="/organisations" element={<OrganisationsView />} />
          <Route path="/organisation/:id" element={<SingleOrgView />} />
          <Route path="/user/:id" element={<UserDetailView />} />
          <Route path="/invite-detail/:id" element={<InviteDetailsView />} />
          <Route path="/edit-user/:userid/:orgid" element={<EditUserView />} />
          <Route path="*" errorElement={<ErrorComponent error="badUrl" />} />
        </Route>
      </Routes>
    )
  }

  return (
    <I18nextProvider i18n={i18nInstance}>
      <GlobalStyleNormalization />
      <GlobalStyle />
      <BrowserRouter basename={basename}>
        <ErrorBoundary>{renderContent()}</ErrorBoundary>
      </BrowserRouter>
    </I18nextProvider>
  )
}

export const Index = observer(IndexComponent)
