export const validateEmail = (emailString: string): boolean => {
  if (!emailString || emailString.length > 254) {
    return false
  }
  const re =
    /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/g
  const regex = RegExp(re)

  return regex.test(emailString)
}
