import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { InviteList, SubTitle, ViewLoading } from '../../components'
import { StoreContext } from '../../store'
import { ContentSection } from '../../style/layout'

export const InviteDetailsComponent: React.FC = () => {
  const { organisationStore } = useContext(StoreContext)
  const { invites, isLoading } = organisationStore

  const { t } = useTranslation()

  if (isLoading) {
    return <ViewLoading />
  }

  return (
    <ContentSection>
      <SubTitle>{` ${t(`landing.invitations`)} (${invites.length})`}</SubTitle>
      <InviteList invites={invites} />
    </ContentSection>
  )
}

export const InviteDetails = observer(InviteDetailsComponent)
