import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useNavigate } from 'react-router'
import styled from 'styled-components'

import { BackButton, InfoBox, NextButton, SelectRoles, Stepper, SubTitle, UserSection } from '../../components'
import { StoreContext } from '../../store'
import { ContentSection } from '../../style/layout'

const InviteStep2Component: React.FC = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    sendPageInfo({
      language: i18n.language,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/invite/2`,
      pageName: 'oneaccount-orgadmin-invite',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { inviteStore, userStore } = useContext(StoreContext)
  const { emailList, roleValues, selectedBusiness, toggleUserRole, pendingRequest, isLoading } = inviteStore

  const selectedOrganisation = userStore.user.organizations.find(
    (org) => org.businessId === selectedBusiness.businessId
  )

  const handleNextClick = useCallback(async () => {
    await inviteStore.getCustomerNumbersAndServiceContracts()
    navigate('/invite/3')
  }, [inviteStore, navigate])

  const handleBackClick = useCallback(() => {
    inviteStore.clearPendingRequest()
    inviteStore.clearStep2State()
    navigate('/invite/1')
  }, [inviteStore, navigate])

  // Redirect user to beginning of flow if emails or selectedBusiness have not been set
  if (pendingRequest === '' && (emailList.length < 1 || !selectedBusiness.businessId)) {
    return <Navigate to="/invite/1" />
  }

  return (
    <ContentSection gap="lg">
      <Stepper />
      <InfoBox />

      <ContentSection>
        <SubTitle>{`3. ${t(`invite.userType`)}`}</SubTitle>
        <UserSection />
      </ContentSection>

      <ContentSection>
        <SubTitle>{t(`invite.accessRights`)}</SubTitle>
        <SelectRoles
          roleGroups={selectedOrganisation.roleGroups}
          selectedRoleValues={roleValues}
          toggleUserRole={toggleUserRole}
          filterInvalidRoles={true}
          hasCustomerNumbers={selectedOrganisation.customerNumbers.length > 0}
          hasLCSNumbers={selectedOrganisation.logisticsContractNumbers.length > 0}
          hasTransportIds={selectedOrganisation.transportIds.length > 0}
          infoTranslationID="general.roleRequirementInfo"
        />
      </ContentSection>

      <StyledNextButtonsWrapper>
        <BackButton onClick={handleBackClick}></BackButton>
        <NextButton loading={isLoading} disabled={roleValues.slice().length <= 0} onClick={handleNextClick} />
      </StyledNextButtonsWrapper>
    </ContentSection>
  )
}

export const InviteStep2 = observer(InviteStep2Component)

const StyledNextButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`
