import { Headline } from '@postidigital/posti-components/build/brand'
import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled from 'styled-components'

import { BackButton, InviteDropDown, NextButton, RoleContractRequirements, SubTitle } from '../../components'
import { StoreContext } from '../../store'
import { DropDownType, IUserDetailOrganisation } from '../../store/dataModels/interfaces'
import { ContentSection } from '../../style/layout'
import {
  arrayIsEmpty,
  sortIDropDownOptionsByDisabledStatusAndValue,
  toDropDownOptions,
  toStringArray,
} from '../../utils/helpers'
import i18n from '../../utils/i18n'

interface IProp {
  organisationData: IUserDetailOrganisation
}

const EditStep2Component: React.FC<IProp> = ({ organisationData }) => {
  const {
    userDetailsStore,
    userStore,
    environmentStore,
    userDetailsStore: { pendingRequest },
  } = useContext(StoreContext)
  const { orgid, userid } = useParams()

  const { t } = useTranslation()

  useEffect(() => {
    sendPageInfo({
      language: i18n.getLocale,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/edit-user/${userid}/${orgid}/2`,
      pageName: 'oneaccount-orgadmin-edit-users',
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { organizationUser, organization } = organisationData
  const {
    customerNumbers,
    setSelectedCustomerNumbers,
    LSCNumbers,
    setSelectedLSCNumbers,
    transportIds,
    setSelectedTransportIds,
    businessPartnerNumbers,
    setSelectedBusinessPartnerNumbers,
  } = userDetailsStore

  const { user } = userStore

  const {
    selectedRoles,
    selectedCustomerNumbers,
    selectedLogisticsContractNumbers,
    selectedTransportIds,
    selectedBusinessPartnerNumbers,
  } = organizationUser

  const getCustomerNumbers = useCallback(() => {
    return businessPartnerNumbers
      .slice()
      .sort(sortIDropDownOptionsByDisabledStatusAndValue)
      .concat(customerNumbers.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue))
  }, [businessPartnerNumbers, customerNumbers])

  const selectCustomerNumbers = useCallback(
    (values) => {
      setSelectedCustomerNumbers(
        organization.businessId,
        toStringArray(values.filter((number) => number.type === DropDownType.CUSTOMER_NUMBER))
      )
      setSelectedBusinessPartnerNumbers(
        organization.businessId,
        toStringArray(values.filter((number) => number.type === DropDownType.BUSINESS_PARTNER_NUMBER))
      )
    },
    [organization.businessId, setSelectedBusinessPartnerNumbers, setSelectedCustomerNumbers]
  )

  const sendChangesPossible: boolean = useMemo(() => {
    // if there are no customer numbers or lcs numbers
    if (!customerNumbers && !LSCNumbers && !transportIds) {
      return true
    }

    const roles = userStore.getRolesThatRequireContracts(organization.businessId, selectedRoles)

    const selectedTypes = []
    if (!arrayIsEmpty(selectedCustomerNumbers.slice())) {
      selectedTypes.push(DropDownType.CUSTOMER_NUMBER)
    }
    if (!arrayIsEmpty(selectedLogisticsContractNumbers.slice())) {
      selectedTypes.push(DropDownType.LOGISTICS_CONTRACT_NUMBER)
    }
    if (!arrayIsEmpty(selectedTransportIds.slice())) {
      selectedTypes.push(DropDownType.TRANSPORT_ID)
    }
    if (!arrayIsEmpty(selectedBusinessPartnerNumbers.slice())) {
      selectedTypes.push(DropDownType.BUSINESS_PARTNER_NUMBER)
    }

    let requirementsOk = true
    roles.forEach((role) => {
      const requiredTypes = []
      if (role.customerNumberRequired) {
        requiredTypes.push(DropDownType.CUSTOMER_NUMBER)
      }
      if (role.logisticsContractNumberRequired) {
        requiredTypes.push(DropDownType.LOGISTICS_CONTRACT_NUMBER)
      }
      if (role.transportIdRequired) {
        requiredTypes.push(DropDownType.TRANSPORT_ID)
      }
      if (role.businessPartnerNumberRequired) {
        requiredTypes.push(DropDownType.BUSINESS_PARTNER_NUMBER)
      }

      const intersection = requiredTypes.filter((item) => selectedTypes.indexOf(item) !== -1)
      if (intersection.length === 0) {
        requirementsOk = false
        return
      }
    })

    return requirementsOk
  }, [
    LSCNumbers,
    customerNumbers,
    organization.businessId,
    selectedBusinessPartnerNumbers,
    selectedCustomerNumbers,
    selectedLogisticsContractNumbers,
    selectedRoles,
    selectedTransportIds,
    transportIds,
    userStore,
  ])

  const handleNext = useCallback(async () => {
    const shouldRefreshToken = organizationUser.accountId === user.accountId
    await userDetailsStore.postChangesToBackend(organization.businessId, shouldRefreshToken)
    userDetailsStore.setStep(3)
  }, [organization.businessId, organizationUser.accountId, user.accountId, userDetailsStore])

  const allOption = {
    label: t(`general.selectAll`),
    value: '*',
    type: DropDownType.SELECT_ALL,
  }

  return (
    <ContentSection>
      {environmentStore.hasFeature('transportId') && (customerNumbers || LSCNumbers || transportIds) && (
        <SubTitle as="h3">{t(`invite.customerNumbersContracts`)}</SubTitle>
      )}
      {!environmentStore.hasFeature('transportId') && (customerNumbers || LSCNumbers || transportIds) && (
        <SubTitle as="h3">{t(`invite.customerNumbersContractsOld`)}</SubTitle>
      )}

      <RoleContractRequirements businessId={organization.businessId} roleValues={selectedRoles} />
      <Headline as="h3" size="Six">
        {t(`general.contractNumbers`)}
      </Headline>

      {(customerNumbers?.length > 0 || businessPartnerNumbers?.length > 0) && (
        <InviteDropDown
          id="customer_number_dropdown"
          key="customer_number_dropdown"
          title={t(`general.selectCustomerNumbers`)}
          placeHolderText={t(`general.customerNumber`)}
          options={getCustomerNumbers()}
          selectedOptions={toDropDownOptions(
            selectedBusinessPartnerNumbers.concat(selectedCustomerNumbers),
            businessPartnerNumbers.concat(customerNumbers).slice().sort(sortIDropDownOptionsByDisabledStatusAndValue),
            allOption
          )}
          onChange={selectCustomerNumbers}
        />
      )}
      {LSCNumbers?.length > 0 && (
        <InviteDropDown
          id="lsc_number_dropdown"
          key="lsc_number_dropdown"
          title={t(`general.selectLogisticsContractNumbers`)}
          placeHolderText={t(`general.LCSNumber`)}
          options={LSCNumbers?.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue)}
          selectedOptions={toDropDownOptions(selectedLogisticsContractNumbers, LSCNumbers, allOption)}
          onChange={(values) => setSelectedLSCNumbers(organization.businessId, toStringArray(values))}
        />
      )}
      {environmentStore.hasFeature('transportId') && transportIds?.length > 0 && (
        <InviteDropDown
          id="transport_id_dropdown"
          key="transport_id_dropdown"
          title={t(`general.selectTransportIds`)}
          placeHolderText={t(`general.transportId`)}
          options={transportIds?.slice().sort(sortIDropDownOptionsByDisabledStatusAndValue)}
          selectedOptions={toDropDownOptions(selectedTransportIds, transportIds, allOption)}
          onChange={(values) => setSelectedTransportIds(organization.businessId, toStringArray(values))}
        />
      )}

      <StyledButtonsWrapper>
        <BackButton
          onClick={() => {
            userDetailsStore.setStep(1)
          }}
        />

        <NextButton
          loading={!!pendingRequest}
          customText={t(`general.makeChanges`)}
          disabled={!sendChangesPossible}
          onClick={handleNext}
        />
      </StyledButtonsWrapper>
    </ContentSection>
  )
}

export const EditStep2 = observer(EditStep2Component)

const StyledButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
`
