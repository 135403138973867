import { ButtonProps } from '@postidigital/posti-components/build/basic-components/Button/Button.types'
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  Button,
  ChevronDownIcon,
  ChevronUpIcon,
  CloseIcon,
  EditIcon,
  KnobsIcon,
  LinkButton,
  PlusIcon,
} from '@postidigital/posti-components/build/brand'
import React, { forwardRef } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

interface IProps extends ButtonProps {
  loading?: boolean
  customText?: string
  id?: string
}

export const NextButton: React.FC<IProps> = ({ onClick, disabled, loading, customText }) => {
  const [t] = useTranslation()

  return (
    <TempFixLoadingButton
      isLoading={loading}
      rightIcon={!loading && ArrowRightIcon}
      id="next_button"
      contentMode="primary"
      disabled={disabled}
      onClick={onClick}
      type="button"
    >
      {customText ? customText : t(`general.next`)}
    </TempFixLoadingButton>
  )
}

export const TempFixLoadingButton = styled(Button)(
  ({ isLoading }) => css`
    ${isLoading &&
    css`
      svg {
        height: 1.5rem;
        width: 1.5rem;
      }
    `}
  `
)

const StyledButtonTitle = styled('span')`
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
`

export const StyledButton = styled(Button)(() => {
  return css`
    display: flex;
    justify-content: space-between;
    width: max-content;
    height: 48px;
    border-radius: 4px;

    ::before {
      border-radius: 4px;
    }
  `
})

export const BackButton: React.FC<IProps> = ({ onClick, disabled }) => {
  const [t] = useTranslation()

  return (
    <Button
      contentMode="secondary"
      leftIcon={StyledArrowLeftIcon}
      hasBackground={false}
      disabled={disabled}
      onClick={onClick}
      id="back_button"
      size="sm"
      css
    >
      <StyledButtonTitle>{t(`general.back`)}</StyledButtonTitle>
    </Button>
  )
}

const StyledArrowLeftIcon = styled(ArrowLeftIcon)`
  margin-right: 16px;
`

export const AddAnotherButton: React.FC<IProps> = ({ onClick, disabled }) => {
  const [t] = useTranslation()

  return (
    <Button
      id="another_button"
      contentMode="secondary"
      hasBackground={false}
      css
      disabled={disabled}
      size="sm"
      onClick={onClick}
      rightIcon={PlusIcon}
    >
      {t(`invite.sendAnotherInvite`)}
    </Button>
  )
}

export const ReadyButton: React.FC<IProps> = ({ onClick, disabled }) => {
  const [t] = useTranslation()

  return (
    <>
      <StyledReadyButton id="ready_button" css disabled={disabled} size="sm" onClick={onClick}>
        <StyledButtonTitle>{t(`general.ready`)}</StyledButtonTitle>
      </StyledReadyButton>
    </>
  )
}

const StyledReadyButton = styled(Button)`
  display: flex;
  justify-content: space-between;
  width: max-content;
  height: 48px;
  margin-left: 24px;

  ::before {
    border-radius: 4px;
  }
`

interface IShowMoreProps {
  id?: string
  type: 'showMore' | 'showLess'
  onClick: () => void
}

export const ShowMoreButton: React.FC<IShowMoreProps> = ({ type, onClick, id }) => {
  const [t] = useTranslation()

  return (
    <StyledShowMoreText>
      <LinkButton
        onClick={onClick}
        id={id || 'show_more_button'}
        icon={type === 'showMore' ? ChevronDownIcon : ChevronUpIcon}
      >
        {type === 'showMore' ? t(`general.showMore`) : t(`general.showLess`)}
      </LinkButton>
    </StyledShowMoreText>
  )
}

const StyledShowMoreText = styled('div')(
  ({
    theme: {
      fontSize: { body },
    },
  }) => css`
    display: flex;
    justify-content: center;
    flex-grow: 1;

    button {
      font-size: ${body.two}rem;
      min-height: 0;
      padding: 0;
    }
  `
)

export const EditButton: React.FC<IProps> = ({ id, ...buttonProps }) => {
  const [t] = useTranslation()

  return (
    <Button
      {...buttonProps}
      id={id ? id : 'edit_button'}
      contentMode="tertiary"
      hasBackground={false}
      rightIcon={EditIcon}
      size="sm"
      css
    >
      {t(`general.edit`)}
    </Button>
  )
}

export const CloseButton: React.FC<IProps> = ({ onClick, disabled }) => {
  const [t] = useTranslation()

  return (
    <>
      <Button
        id="close_button"
        css
        contentMode="secondary"
        hasBackground={false}
        disabled={disabled}
        size="sm"
        onClick={onClick}
        rightIcon={CloseIcon}
      >
        {t(`general.close`)}
      </Button>
    </>
  )
}

// eslint-disable-next-line react/display-name
export const RemoveButton = forwardRef<HTMLButtonElement, IProps>(({ onClick, disabled, id }, ref) => {
  const [t] = useTranslation()

  return (
    <Button
      ref={ref}
      id={id ? id : 'remove_button'}
      contentMode="tertiary"
      hasBackground={false}
      rightIcon={CloseIcon}
      css
      disabled={disabled}
      size="sm"
      onClick={onClick}
    >
      {t(`removeModal.removeButton`)}
    </Button>
  )
})

export const FilterButton: React.FC<IProps & { selected: boolean }> = ({ selected, onClick }) => {
  const { t } = useTranslation()

  return (
    <Button
      size="sm"
      rightIcon={KnobsIcon}
      onClick={onClick}
      contentMode={selected ? 'primary' : 'secondary'}
      hasBackground
    >
      {t('filter.filter')}
    </Button>
  )
}

export const DoneButton: React.FC<IProps> = ({ onClick, disabled, customText }) => {
  const [t] = useTranslation()

  return (
    <Button id="done_button" css disabled={disabled} size="sm" onClick={onClick}>
      {customText ? customText : t(`general.done`)}
    </Button>
  )
}
