import i18next, { Resource, TFunction } from 'i18next'
import { action, computed, makeObservable, observable } from 'mobx'
import moment from 'moment'

import { InboundMessageType } from './postMessage'

export const SHORT_DATE = 'D.M.'
export const LONG_DATE = 'D.M.YYYY'

export enum Locale {
  FI = 'fi',
  EN = 'en',
  SV = 'sv',
}

const localeFromParentApp = document.getElementById('oneaccount-orgadmin-front')?.dataset?.locale

export const defaultLocale = Locale.EN

const MOMENT_START_OF_YEAR = moment().startOf('year').startOf('day')
const TODAY_IS_NEAR_YEAR_START = moment().startOf('day').isBefore(MOMENT_START_OF_YEAR.add(1, 'month'))
const MOMENT_WEEK_AGO = moment().subtract(1, 'week').startOf('day')

export const i18nInstance = i18next.createInstance()

export class I18n {
  locale: Locale | string = localeFromParentApp || defaultLocale

  constructor(locale: Locale | string) {
    makeObservable(this, {
      locale: observable,
      getLocale: computed,
      changeLocale: action,
      changeLocaleOnMessage: action,
      isValidLocale: action,
      addResourceBundle: action,
      init: action,
    })

    this.locale = locale
  }

  removeListeners() {
    window.removeEventListener('message', this.changeLocaleOnMessage, false)
  }

  get getLocale() {
    return this.locale
  }

  changeLocale(locale) {
    if (!this.isValidLocale(locale)) {
      throw Error('Invalid locale parameter given for i18n changeLocale function')
    }
    this.locale = locale

    i18nInstance.changeLanguage(this.locale)

    moment.locale(this.locale)
    moment.updateLocale(this.locale, this.createRelativeDateConfig(t))
  }

  changeLocaleOnMessage(event) {
    if (event.origin !== window.location.origin) {
      return null
    }

    const data = event.data

    if (InboundMessageType.LANGUAGE === data.type) {
      this.changeLocale(event.data.payload)
    }
  }

  isValidLocale(locale) {
    const validLocales = Object.keys(Locale).map((key) => Locale[key])
    return typeof locale === 'string' && validLocales.includes(locale)
  }

  private createRelativeDateConfig = (t: TFunction) => {
    const defaultResolver = function () {
      // If the date is from this week, return day of week (Mon, Tue, ...)
      if (this.isSameOrAfter(MOMENT_WEEK_AGO)) {
        return 'ddd'
      }
      // If the date is from last year or before, return long date, only if the date is not near the start of a year
      if (this.isSameOrBefore(MOMENT_START_OF_YEAR) && !TODAY_IS_NEAR_YEAR_START) {
        return 'D.M.YYYY'
      }
      // Else, return the short date format
      return 'D.M.'
    }

    return {
      calendar: {
        sameDay: `[${t('formatters.today')}]`,
        lastDay: `[${t('formatters.yesterday')}]`,
        nextDay: defaultResolver,
        lastWeek: defaultResolver,
        nextWeek: defaultResolver,
        sameElse: defaultResolver,
      },
    }
  }

  addResourceBundle = (locale: Locale, ns: string, resource: Resource) => {
    if (locale && ns && resource) {
      i18nInstance.addResourceBundle(locale, ns, resource)
    }
  }

  init(translations: Resource, ns: string) {
    const htmlLocale = typeof document !== 'undefined' ? document.documentElement.lang.toLowerCase() : defaultLocale

    Object.keys(Locale).forEach((l) => {
      if (Locale[l] === htmlLocale) {
        this.locale = htmlLocale as Locale

        return
      }
    })

    window.addEventListener('message', this.changeLocaleOnMessage, false)

    i18nInstance.init({
      resources: translations,
      lng: this.locale,
      whitelist: Object.values(Locale),
      fallbackLng: Locale.FI,
      ns: [ns],
      defaultNS: ns,
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: true,
      },
    })

    moment.locale(Locale.FI)
    moment.updateLocale(Locale.FI, this.createRelativeDateConfig(t))
  }
}

export const t = i18nInstance.t.bind(i18nInstance)
export default new I18n(i18nInstance.language)
