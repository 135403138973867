import { action, makeObservable, observable } from 'mobx'

class AuthStore {
  idToken: string | null

  roleToken: string | null

  accessToken: string | null

  requestTokenRefresh: (() => void) | null

  setTokenRefreshCb = (cb: () => void | any) => {
    if (typeof cb !== 'function') {
      return null
    }

    this.requestTokenRefresh = cb
  }

  refreshTokens = () => this.requestTokenRefresh?.()

  setIdToken = (idToken: string): void => {
    this.idToken = idToken
  }

  getIdToken = (): string => {
    return this.idToken
  }

  setRoleToken = (roleToken: string): void => {
    this.roleToken = roleToken
  }

  setAccessToken = (accessToken: string): void => {
    this.accessToken = accessToken
  }

  getAccessToken = (): string => {
    return this.accessToken
  }

  reset = (): void => {
    this.idToken = null
    this.roleToken = null
    this.accessToken = null
  }

  constructor() {
    makeObservable(this, {
      idToken: observable,
      roleToken: observable,
      accessToken: observable,
      requestTokenRefresh: observable,
      setTokenRefreshCb: action,
      refreshTokens: action,
      setIdToken: action,
      getIdToken: action,
      setRoleToken: action,
      setAccessToken: action,
      getAccessToken: action,
      reset: action,
    })
  }
}

export const authStore = new AuthStore()
