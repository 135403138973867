import { CSATButtonGroup, FeedbackButton } from '@postidigital/posti-components'
import {
  Body,
  FaceFrownIcon,
  FaceHappyIcon,
  FaceNeutralIcon,
  FaceSadIcon,
  FaceSmileIcon,
  Headline,
} from '@postidigital/posti-components/build/brand'
import { ButtonGroupProps } from '@postidigital/posti-components/build/composite-components/ButtonGroup/ButtonGroup.types'
import { sendUserAction } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

const CSATSelectorComponent: React.FC = () => {
  const [sent, setSent] = useState(false)
  const [t] = useTranslation()

  const handleOnSelect: ButtonGroupProps['onChange'] = (_, value) => {
    if (sent) {
      return
    }
    setSent(true)
    // Send csat value to GA
    sendUserAction({
      actionType: 'gaveRating',
      ratingValue: `${value}`,
    })
  }

  const renderCSATSelector = () => (
    <CSATButtonGroup selectedValue={undefined} onChange={handleOnSelect}>
      <FeedbackButton
        aria-label={t('csat.terrible.aria')}
        label={t('csat.terrible.label')}
        value="1"
        isBrand
        icon={FaceFrownIcon}
      />
      <FeedbackButton
        aria-label={t('csat.bad.aria')}
        label={t('csat.bad.label')}
        value="2"
        isBrand
        icon={FaceSadIcon}
      />
      <FeedbackButton
        aria-label={t('csat.ok.aria')}
        label={t('csat.ok.label')}
        value="3"
        isBrand
        icon={FaceNeutralIcon}
      />
      <FeedbackButton
        aria-label={t('csat.good.aria')}
        label={t('csat.good.label')}
        value="4"
        isBrand
        icon={FaceSmileIcon}
      />
      <FeedbackButton
        aria-label={t('csat.great.aria')}
        label={t('csat.great.label')}
        value="5"
        isBrand
        icon={FaceHappyIcon}
      />
    </CSATButtonGroup>
  )

  return (
    <>
      <Headline as="h2" size="Six">
        {sent ? t('csat.thanks.title') : t('csat.title')}
      </Headline>
      {!sent && renderCSATSelector()}
      {sent && <Body size="Five">{t('csat.thanks.text')}</Body>}
    </>
  )
}

export const CSATSelector = observer(CSATSelectorComponent)
