import { CloseIcon, IconButton, Label } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { StoreContext } from '../../store'

const EmailItemComponent: React.FC<{ email: string }> = ({ email }) => {
  const { inviteStore } = useContext(StoreContext)
  const { t } = useTranslation()
  const label = t('invite.removeEmailButtonLabel', { email })
  const handleClose = useCallback(() => inviteStore.removeEmail(email), [email, inviteStore])

  return (
    <StyledWrapper>
      <Label as="div" size="Two" key={email}>
        {email}
      </Label>
      <IconButton icon={CloseIcon} aria-label={label} onClick={handleClose} />
    </StyledWrapper>
  )
}

export const EmailItem = observer(EmailItemComponent)

const StyledWrapper = styled.div(
  ({
    theme: {
      brand: {
        spacing: { space4 },
      },
    },
  }) => css`
    align-items: center;
    display: flex;
    gap: ${space4}rem;
  `
)
