import { ListItem } from '@postidigital/posti-components'
import { CheckmarkCircledIcon } from '@postidigital/posti-components/build/brand'
import { BrandTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import styled, { css } from 'styled-components'

import { StoreContext } from '../../store'

const ToggleableSuccessIcon: React.FC<{ checked: boolean }> = ({ checked }) => {
  if (checked) {
    return <CheckmarkCircledIcon height="1.5rem" color={BrandTheme.color.foreground.success.default} />
  }

  return <CheckmarkCircledIcon color={BrandTheme.color.foreground.core.disabled} height="1.5rem" />
}

const StepperComponent: React.FC = () => {
  const { environmentStore } = useContext(StoreContext)
  const [t] = useTranslation()
  const { pathname } = useLocation()
  const step = parseInt(pathname[pathname.length - 1])

  return (
    <StyledStepperContainer>
      <StyledListItem
        size="lg"
        subtitle="Step 1"
        title={t(`invite.orgAndUsers`)}
        current={step === 1}
        checked={step > 1}
        rightContent={<ToggleableSuccessIcon checked={step > 1} />}
      />
      <StyledListItem
        size="lg"
        subtitle="Step 2"
        title={t(`invite.accessRights`)}
        current={step === 2}
        checked={step > 2}
        rightContent={<ToggleableSuccessIcon checked={step > 2} />}
      />
      <StyledListItem
        size="lg"
        subtitle="Step 3"
        title={
          environmentStore.hasFeature('transportId')
            ? t(`invite.customerNumbersContracts`)
            : t(`invite.customerNumbersContractsOld`)
        }
        current={step === 3}
        checked={step > 3}
        rightContent={<ToggleableSuccessIcon checked={step > 3} />}
      />
    </StyledStepperContainer>
  )
}

export const Stepper = observer(StepperComponent)

const StyledListItem = styled(ListItem)<{
  current: boolean
  checked: boolean
}>(
  ({
    theme: {
      brand: {
        color: {
          foreground: {
            success,
            accent: { lowContrast },
          },
          border: {
            core: { highContrast },
          },
        },
      },
    },
    current,
    checked,
  }) => css`
    border-bottom: 1px solid ${highContrast.default};
    width: 33.333%;

    ${checked &&
    css`
      border-bottom: 4px solid ${success.default};
      border-radius: 2px 2px 0 0;
    `}

    ${current &&
    css`
      border-bottom: 4px solid ${lowContrast.default};
      border-radius: 2px 2px 0 0;
    `}
  `
)

const StyledStepperContainer = styled.div`
  display: flex;
  flex-direction: row;
`
