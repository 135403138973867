import { Headline } from '@postidigital/posti-components/build/brand'
import { toJS } from 'mobx'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { SelectRoles } from '../../components'
import { StoreContext } from '../../store'
import { IRoleGroupModel } from '../../store/dataModels/interfaces'
import { ContentSection } from '../../style/layout'

const FilterServicesListComponent: React.FC = () => {
  const { searchStore } = useContext(StoreContext)
  const { organizations, selectedOrganisations, selectedRoles, toggleSelectedRole } = searchStore
  const [t] = useTranslation()

  const roleGroups: IRoleGroupModel[] = Object.values(
    toJS(selectedOrganisations) // Start with a list of selected dropdown options
      .filter((org) => org.value !== '*') // filter out "all organizations" value from dropdown
      .map((org) => toJS(organizations.find((orgz) => orgz.businessId === org.value))) // Map dropdown options to actual organizations
      .reduce((acc, it) => acc.concat(it.roleGroups), []) // Combine all rolegroups from all organizations in a single list
      .reduce((acc, it) => {
        // Combine duplicate rolegroups together
        // - If rolegroup is not yet added, take it as it is
        // - Otherwise ensure that each role is included in the rolegroup only once
        // This results in an associative array, which is converted to a normal array by the wrapping "Object.values"-method
        if (!acc[it.value]) {
          acc[it.value] = it
        } else {
          const missingRoles = it.roles.filter(
            (r) => !acc[it.value].roles.some((existing) => existing.value == r.value)
          )
          acc[it.value].roles.concat(missingRoles)
        }
        return acc
      }, [])
  )

  return (
    <ContentSection top="lg">
      <Headline as="h2" size="Seven">
        {t('filter.byService')}
      </Headline>
      <SelectRoles
        roleGroups={roleGroups}
        selectedRoleValues={selectedRoles}
        toggleUserRole={(role) => toggleSelectedRole(role)}
        filterStyles
      />
    </ContentSection>
  )
}

export const FilterServicesList = observer(FilterServicesListComponent)
