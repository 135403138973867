import { makeObservable, observable, runInAction } from 'mobx'

import { serviceAdapter } from '../service/serviceAdapter'
import { IInvite, IOrgUser } from './dataModels/interfaces'

//  This store holds the data for a single organisation
export class OrganisationStore {
  mainUsers: IOrgUser[]

  normalUsers: IOrgUser[]

  invites: IInvite[]

  isLoading: boolean = true

  constructor() {
    makeObservable(this, {
      mainUsers: observable,
      normalUsers: observable,
      invites: observable,
      isLoading: observable,
    })
  }

  async initalFetch(businessId: string): Promise<void> {
    runInAction(() => {
      this.isLoading = true
    })
    await Promise.all([this.fetchOrgUsers(businessId), this.fetchOrgInvites(businessId)])
    runInAction(() => {
      this.isLoading = false
    })
  }

  async fetchOrgUsers(businessId: string): Promise<void> {
    try {
      const response = await serviceAdapter.sendGetRequest(`/api/organization-users/${businessId}`)

      if (response.status >= 400) {
        throw new Error('Bad response from server')
      }
      const users = await response.json()

      runInAction(() => {
        const [mainUsers, normalUsers] = sortUsers(users)
        this.mainUsers = mainUsers
        this.normalUsers = normalUsers
      })
    } catch (err) {
      console.error(err)
    }
  }

  async fetchOrgInvites(businessId: string): Promise<void> {
    try {
      const response = await serviceAdapter.sendGetRequest(`/api/invites/organization/${businessId}`)

      if (response.status >= 400) {
        throw new Error('Bad response from server')
      }
      const invites = await response.json()
      runInAction(() => {
        this.invites = invites
      })
    } catch (err) {
      console.error(err)
    }
  }
}

export const organisationStore = new OrganisationStore()

function sortUsers(users: IOrgUser[]): [IOrgUser[], IOrgUser[]] {
  const normalUsers = []
  const mainUsers = []

  users.map((user) => {
    if (isMainUser(user)) {
      mainUsers.push(user)
    } else {
      normalUsers.push(user)
    }
  })

  return [mainUsers, normalUsers]
}

function isMainUser(user: IOrgUser): boolean {
  return user.selectedRoles.includes('MainUser')
}
