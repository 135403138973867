import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import { components } from 'react-select'

import { IDropDownOption } from '../../../store/dataModels/interfaces'
import AllSelect from './AllSelect'

// If you are using mobx, be sure to covert your options to JS
// before passing as props to this component
// const optionsToJs = toJS(options)
// const selectedOptionsToJs = toJS(selectedOptions)
interface IProps {
  id?: string | number
  isSearchable?: boolean
  options: IDropDownOption[]
  selectedOptions: IDropDownOption[]
  isMulti?: boolean
  placeHolderText?: string
  onChange?: (values: IDropDownOption[]) => any
  allowSelectAll?: boolean
  allOption: IDropDownOption // required, will brake if not passed
  // This disbles all options, you can still view them
  // do not pass a onChange function
  disabled?: boolean
  // if you want to disable a single option. You have to
  // pass the option with a disabled prop EG:
  // const options = [
  // [{label: "one", value: 1, disabled: true},
  // {label: "two", value: 2}]
  closeMenuOnSelect?: boolean
  menuPlacement?: string
  menuPosition?: string
}

const DropdownComponent: React.FC<IProps> = ({
  id,
  options,
  onChange,
  selectedOptions,
  isMulti = false,
  isSearchable = false,
  placeHolderText = '',
  allowSelectAll = false,
  allOption,
  closeMenuOnSelect = true,
}) => {
  const formatOptionLabel = useCallback(
    ({ isDisabled, label, value }) => {
      return (
        <div key={`${id}-${value}`} style={isDisabled ? { color: 'rgb(194, 201, 209)' } : {}}>
          {label}
        </div>
      )
    },
    [id]
  )

  return (
    <AllSelect
      id={id}
      isSearchable={isSearchable}
      value={selectedOptions}
      onChange={onChange}
      isMulti={isMulti}
      options={options}
      placeholder={placeHolderText}
      components={{ ValueContainer }}
      noOptionsMessage={() => null}
      hideSelectedOptions={false}
      allowSelectAll={allowSelectAll}
      allOption={allOption}
      closeMenuOnSelect={closeMenuOnSelect}
      menuPlacement="auto"
      menuPosition="absolute"
      formatOptionLabel={formatOptionLabel}
    />
  )
}

const ValueContainer = ({ children, ...props }: any) => {
  const allOption = props.options[0]

  const currentValues = props.getValue()
  let toBeRendered = children
  if (currentValues.some((val) => val.value === allOption.value)) {
    // Render "all" option and an input element
    toBeRendered = [[children[0][0]], children[1]]
  }

  return <components.ValueContainer {...props}>{toBeRendered}</components.ValueContainer>
}

export const Dropdown = observer(DropdownComponent)
