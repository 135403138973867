import { postMessageToOmaPosti } from '@postidigital/posti-components'

import { ID } from '../constants'

export enum InboundMessageType {
  TOKENS = 'TOKENS',
  UNMOUNT = 'UNMOUNT',
  LANGUAGE = 'LANGUAGE',
}

export enum OutboundMessageType {
  READY = 'READY',
  REFRESH = 'REFRESH_TOKENS',
}

export interface InboundMessage {
  type: InboundMessageType
  payload: any
  sender: ID.OMAPOSTI | string
  receiver: ID.FEATURE
}

/**
 * Send message to OmaPosti
 */
export const sendMessage = ({ type, payload }: { type: OutboundMessageType; payload: any }) => {
  postMessageToOmaPosti({ type, payload, sender: ID.FEATURE, receiver: ID.OMAPOSTI }, '*')
}

export const sendInboundMessage = ({ type, payload }: { type: InboundMessageType; payload: any }) => {
  postMessageToOmaPosti({ type, payload, sender: ID.OMAPOSTI, receiver: ID.FEATURE }, '*')
}
