import { ListItem } from '@postidigital/posti-components'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { StoreContext } from '../../store'
import { ContentSection } from '../../style/layout'
import { SubTitle, ViewLoading } from '../index'
import { getOrgUserPath } from './OrganizationUtil'
import { WideCard } from './WideCard'

interface IProps {
  businessId: string
}

export const OrganisationDetailsComponent: React.FC<IProps> = ({ businessId }) => {
  const { organisationStore } = useContext(StoreContext)
  const { mainUsers, isLoading } = organisationStore
  const { t } = useTranslation()

  if (isLoading) {
    return <ViewLoading />
  }

  const renderMainUsers = (mainUsers) => {
    return mainUsers.map((user) => {
      return (
        <WideCard
          to={getOrgUserPath(user.organizationUserId)}
          key={user.organizationUserId}
          title={`${user.firstName} ${user.lastName}`}
          subtitle={user.email}
        />
      )
    })
  }

  return (
    <ContentSection>
      <ContentSection>
        <StyledListItem title={businessId} subtitle={t(`general.businessId`)} size="md" />
      </ContentSection>
      <ContentSection>
        <SubTitle>{t(`general.mainUsers`)}</SubTitle>
        <div>{renderMainUsers(mainUsers)}</div>
      </ContentSection>
    </ContentSection>
  )
}

export const OrganisationDetails = observer(OrganisationDetailsComponent)

const StyledListItem = styled(ListItem)`
  margin-left: 16px;
`
