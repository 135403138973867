import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { useParams } from 'react-router'

import { EditStep1, EditStep2, EditStep3, EditTopSection, ViewLoading } from '../components'
import { StoreContext } from '../store'

const EditUserViewComponent: React.FC = () => {
  const { userDetailsStore } = useContext(StoreContext)
  const { userid, orgid } = useParams()

  useEffect(() => {
    const { firstName } = userDetailsStore
    // if userDetails have not been fetched, but the user still navigates
    // to this view, fetch the data

    if (!firstName) {
      userDetailsStore.fetchUserData(userid)
    }

    // cleanup
    return () => {
      userDetailsStore.setChangesMade(false)
      userDetailsStore.setStep(1)
      userDetailsStore.setPendingRequest('')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (userDetailsStore.isLoading) {
    return <ViewLoading />
  }

  const organisationData = userDetailsStore.userOrganizations.find((org) => org.organization.businessId === orgid)

  const { step } = userDetailsStore

  if (step === 1) {
    return (
      <>
        <EditTopSection />
        <EditStep1 organisationData={organisationData} />
      </>
    )
  }

  if (step === 2) {
    return (
      <>
        <EditTopSection />
        <EditStep2 organisationData={organisationData} />
      </>
    )
  }

  if (step === 3) {
    return (
      <>
        <EditTopSection />
        <EditStep3 organisationData={organisationData} />
      </>
    )
  }
}

export const EditUserView = observer(EditUserViewComponent)
