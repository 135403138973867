import { Button } from '@postidigital/posti-components'
import { sendPageInfo } from '@postidigital/posti-google-analytics'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled, { css } from 'styled-components'

import ErrorLarge from '../../static/img/error_large'
import { StoreContext } from '../../store'

const InviteFailureComponent: React.FC = () => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  useEffect(() => {
    sendPageInfo({
      language: i18n.language,
      solution: 'oneaccount-orgadmin',
      domain: window.location.hostname,
      pagePath: `/org-admin/invite/failure`,
      pageName: 'oneaccount-orgadmin-invite',
    })
    // On component unmount clear store
    return function cleanup() {
      inviteStore.clearStore()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { inviteStore } = useContext(StoreContext)

  let error = 'email_reserved'

  if (inviteStore.errorCode != '') {
    error = inviteStore.errorCode.toLowerCase()
  }
  if (!i18n.exists(`errors.invite_${error}.title`)) {
    error = 'general'
  }

  return (
    <StyledErrorContainer>
      <ErrorLarge />
      <StyledTitle>{t(`errors.invite_${error}.title`)}</StyledTitle>
      {i18n.exists(`errors.invite_${error}.body`) && (
        <StyledBodyText>{t(`errors.invite_${error}.body`)}</StyledBodyText>
      )}
      <StyledButton
        css
        title={t(`errors.reload`)}
        onClick={() => {
          navigate('/')
          window.location.reload()
        }}
      >
        {t(`errors.refresh`)}
      </StyledButton>
    </StyledErrorContainer>
  )
}

const StyledButton = styled(Button)`
  margin-top: 32px;
`
export const InviteFailure = observer(InviteFailureComponent)

const StyledErrorContainer = styled.div(
  () => css`
    height: 100%;
    padding: 0;
    margin: 48px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  `
)

const StyledTitle = styled.div(
  () => css`
    font-family: PostiSans, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 39px;
    line-height: 40px;
    letter-spacing: -0.01em;

    color: #000000;
    margin-top: 16px;

    text-align: center;
  `
)

const StyledBodyText = styled.div(
  ({ theme }) => css`
    font-family: ${theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;
    color: #000000;
    text-align: center;
    margin-top: 8px;
  `
)
