import { ArrowRightIcon, Body, Button, ProfileIcon } from '@postidigital/posti-components/build/brand'
import { BrandTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router'
import styled, { css } from 'styled-components'

import { breakpoint } from '../../utils/breakpoint'

interface IProps {
  title: string
  to: string
  subtitle?: string
}

const WideCardComponent: React.FC<IProps> = ({ title, to, subtitle }) => {
  const navigate = useNavigate()

  const handleClick = useCallback(() => {
    navigate(to)
  }, [navigate, to])

  return (
    <StyledOrganisationWrapper role="button" key={title} onClick={handleClick}>
      <div>
        <StyledProfileIcon color={BrandTheme.color.foreground.accent.highContrast.default} />
      </div>

      <div>
        <StyledBody as="div" size="Three">
          {title}
        </StyledBody>
        {subtitle && <StyledSubTitle>{subtitle}</StyledSubTitle>}
      </div>

      <MarginLeftDiv>
        <Button contentMode="tertiary" rightIcon={ArrowRightIcon} />
      </MarginLeftDiv>
    </StyledOrganisationWrapper>
  )
}

export const WideCard = observer(WideCardComponent)

const StyledBody = styled(Body)`
  font-weight: 600;
`

const StyledOrganisationWrapper = styled.div(
  () => css`
    display: flex;
    flex-direction: row;
    min-height: 80px;

    width: 100%;

    align-items: center;

    font-family: ${({ theme }) => theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 155.6%;
    color: ${({ theme }) => theme.color.neutralBlack};
    cursor: pointer;

    @media ${breakpoint.mobile} {
      padding: ${({ theme }) => theme.spacing.space2}rem;
      min-height: 100px;
      margin-top: 8px;
    }
  `
)

const MarginLeftDiv = styled.div`
  margin-left: auto;
`

const StyledProfileIcon = styled(ProfileIcon)`
  padding-right: 8px;
`

const StyledSubTitle = styled.div`
  display: flex;
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;

  word-break: break-all;
  color: ${({ theme }) => theme.color.neutralNetworkGray};
`
