import { Tabs } from '@postidigital/posti-components'
import { observer } from 'mobx-react-lite'
import React, { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router'

import { InviteDetails, MainTitle, OrganisationDetails, UserDetails, ViewLoading } from '../components'
import { StoreContext } from '../store'
import { ContentSection } from '../style/layout'

const SingleOrgComponent: React.FC = () => {
  const { userStore, organisationStore } = useContext(StoreContext)
  const { id } = useParams()
  const { t } = useTranslation()

  useEffect(() => {
    organisationStore.initalFetch(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { user } = userStore
  const org = useMemo(() => user?.organizations?.find((org) => org.businessId === id), [id, user])

  if (userStore.isLoading || organisationStore.isLoading) {
    return <ViewLoading />
  }

  if (!org) {
    return <Navigate to="/organisations" />
  }

  return (
    <ContentSection gap="lg">
      <MainTitle>{org.name}</MainTitle>
      <Tabs isBrand aria-label="Your parcels" defaultSelectedKey="details">
        <Tabs.TabItem key="details" title={t(`general.orgDetails`)}>
          <OrganisationDetails businessId={id} />
        </Tabs.TabItem>
        <Tabs.TabItem key="users" title={t(`general.users`)}>
          <UserDetails />
        </Tabs.TabItem>
        <Tabs.TabItem key="invitations" title={t(`general.invites`)}>
          <InviteDetails />
        </Tabs.TabItem>
      </Tabs>
    </ContentSection>
  )
}

export const SingleOrgView = observer(SingleOrgComponent)
