import { CheckmarkCircledIcon, Headline, ProfileIcon } from '@postidigital/posti-components/build/brand'
import { BrandTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import styled, { css } from 'styled-components'

import { StoreContext } from '../../store'

const InfoBoxComponent: React.FC = () => {
  const { inviteStore } = useContext(StoreContext)

  const { emailList, selectedBusiness } = inviteStore
  const { name, businessId } = selectedBusiness

  return (
    <StyledInfoBox>
      <StyledContentWrapper>
        <StyledHeadline as="div" size="Six">
          {`${name} (${businessId})`}
        </StyledHeadline>

        {emailList.map((email) => {
          return (
            <StyledText key={email}>
              <StyledProfileIcon />
              {email}
            </StyledText>
          )
        })}
      </StyledContentWrapper>
      <StyledIconWrapper>
        <CheckmarkCircledIcon
          color={BrandTheme.color.foreground.accent.lowContrast.default}
          height="1.5rem"
          width="1.5rem"
        />
      </StyledIconWrapper>
    </StyledInfoBox>
  )
}

export const InfoBox = observer(InfoBoxComponent)

const StyledHeadline = styled(Headline)`
  margin-right: 8px;
  margin-bottom: 16px;
`

const StyledProfileIcon = styled(ProfileIcon)`
  margin-right: 8px;
`

const StyledInfoBox = styled('div')`
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  border: 2px solid ${({ theme }) => theme.brand.color.surface.accent.highContrast.default};
  flex-direction: row;
  position: relative;
`

const StyledIconWrapper = styled('div')(
  ({
    theme: {
      brand: {
        spacing: { space2 },
      },
    },
  }) => css`
    position: absolute;
    right: ${space2}rem;
    top: ${space2}rem;
  `
)

const StyledContentWrapper = styled('div')`
  margin: 1rem;
`

const StyledText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;
  display: flex;
  align-items: center;
  color: #000000;
`
