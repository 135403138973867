import moment from 'moment'

export const formatDate = (dateTime: string | object): string => {
  if (typeof dateTime === 'string') {
    // the code should never go here, but it's a good fallback in case old type of data is passed
    // from the backend
    return formatDateString(dateTime)
  }

  // ToDo Evaluate the use case below
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const day = dateTime.dateTime.date.day
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const monthIndex = dateTime.dateTime.date.month
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const year = dateTime.dateTime.date.year

  return day + '.' + monthIndex + '.' + year
}

export const formatDateString = (dateString: string): string => {
  const date = new Date(Date.parse(dateString))
  const day = date.getUTCDate()
  const monthIndex = date.getUTCMonth() + 1
  const year = date.getUTCFullYear()

  return day + '.' + monthIndex + '.' + year
}

const formatDateDaysLeft = (dateObj: any): string => {
  const dateString = formatDate(dateObj)

  const fixedString = dateStringToISO(dateString)

  const eventdate = moment(fixedString)
  const todaysdate = moment()
  const daysleft = eventdate.diff(todaysdate, 'days')

  if (daysleft < 0) {
    return '0'
  }

  return daysleft.toString()
}

export const getInviteState = (
  cancelled: string | null,
  expired: boolean | null,
  expires: string | null,
  accepted: string | null,
  t: (string: string) => string
): string => {
  if (accepted) {
    return t(`general.accepted`)
  }

  if (cancelled) {
    return t(`general.cancelled`)
  }

  if (expired) {
    return t(`general.expired`)
  }

  return `${formatDateDaysLeft(expires)} ${t(`landing.dayleft`)}`
}

const dateStringToISO = (dateString: string): string => {
  return dateString
    .split('.')
    .map((item) => (item.length < 2 ? '0' : '') + item)
    .reverse()
    .join('-')
}
