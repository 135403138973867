import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useMemo, useState } from 'react'

import { ShowMoreButton, WideCard } from '../../components'
import { StoreContext } from '../../store'
import { ContentSection } from '../../style/layout'
import { checkIfMobile } from '../../utils/breakpoint'

export const LandingUsersComponent: React.FC = () => {
  const defaultUserCount = 10
  const [visibleUsers, setvisibleUsers] = useState(defaultUserCount)
  const { searchStore } = useContext(StoreContext)

  const { users } = searchStore

  const handleAddUsers = useCallback(() => {
    if (checkIfMobile()) {
      return setvisibleUsers(visibleUsers + 3)
    }
    return setvisibleUsers(visibleUsers + 20)
  }, [visibleUsers])

  const handleHideUsers = useCallback(() => {
    return setvisibleUsers(defaultUserCount)
  }, [])

  const renderShowButton = useMemo(() => {
    if (visibleUsers < users.length) {
      return (
        <ShowMoreButton
          id="show_more_users_button"
          onClick={() => {
            handleAddUsers()
          }}
          type="showMore"
        />
      )
    }

    if (visibleUsers !== defaultUserCount) {
      return (
        <ShowMoreButton
          id="show_less_users_button"
          onClick={() => {
            handleHideUsers()
          }}
          type="showLess"
        />
      )
    }
  }, [handleAddUsers, handleHideUsers, users.length, visibleUsers])

  const renderUsers = useMemo(
    () =>
      users
        .slice(0, visibleUsers)
        .map((user) => (
          <WideCard
            to={`/user/${user.organizationUserId}`}
            key={user.organizationUserId}
            title={`${user.firstName} ${user.lastName}`}
            subtitle={user.email}
          />
        )),
    [users, visibleUsers]
  )

  return (
    <ContentSection>
      <div>{renderUsers}</div>
      {renderShowButton}
    </ContentSection>
  )
}

export const LandingUsers = observer(LandingUsersComponent)
