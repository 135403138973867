import { ListItem } from '@postidigital/posti-components/'
import { Button, CloseIcon, Notification, SendIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { MouseEventHandler, useCallback, useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import styled, { css } from 'styled-components'

import { MainTitle, OrganisationExpander, StyledButton, ViewLoading } from '../components'
import { StoreContext } from '../store'
import { ContentSection } from '../style/layout'
import { formatDate, getInviteState } from '../utils'
import { breakpoint } from '../utils/breakpoint'

const InviteDetailsViewComponent: React.FC = () => {
  const [t] = useTranslation()
  const { inviteDetailsStore } = useContext(StoreContext)
  const { id: inviteId } = useParams()
  const {
    isLoading,
    inviteDetail,
    fetchingStage,
    selectedRoleValues,
    selectedCustomerNumbers,
    selectedLCNumbers,
    selectedTransportIds,
    selectedBusinessPartnerNumbers,
  } = inviteDetailsStore

  useEffect(() => {
    inviteDetailsStore.fetchInviteData(inviteId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleResend: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      return inviteDetailsStore.resendInvite(event.currentTarget.value)
    },
    [inviteDetailsStore]
  )

  const handleCancel: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      return inviteDetailsStore.cancelInvite(event.currentTarget.value)
    },
    [inviteDetailsStore]
  )

  if (isLoading) {
    return <ViewLoading />
  }

  const { email, created, expires, expired, cancelled, accepted } = inviteDetail

  return (
    <ContentSection gap="lg">
      <ContentSection>
        <MainTitle>{t(`invite.inviteDetailsTitle`)}</MainTitle>
        <StyledDetailsWrapper>
          <ListItem
            id="invite_details_created_at"
            title={formatDate(created)}
            subtitle={t(`general.created`)}
            size="md"
          />
          <ListItem id="invite_details_email" title={email} subtitle={t(`invite.userUsername`)} size="md" />
          <ListItem
            id="invite_details_state"
            title={getInviteState(cancelled, expired, expires, accepted, t)}
            subtitle={t(`general.valid`)}
            size="md"
          />
        </StyledDetailsWrapper>
      </ContentSection>
      <ContentSection>
        {fetchingStage && (
          <Notification contentType={getNotiType(fetchingStage)}>
            <span>{getNotiText(fetchingStage, t)}</span>
          </Notification>
        )}

        <StyledOrgExpanderWrapper>
          <OrganisationExpander
            organization={inviteDetail.organization}
            selectedRoleValues={selectedRoleValues}
            selectedCustomerNumbers={selectedCustomerNumbers}
            selectedLCNumbers={selectedLCNumbers}
            selectedTransportIds={selectedTransportIds}
            selectedBusinessPartnerNumbers={selectedBusinessPartnerNumbers}
          />
          <StyledButtonsWrapper>
            <Button
              disabled={Boolean(accepted)}
              id="resend_button"
              value={inviteId}
              onClick={handleResend}
              contentMode="tertiary"
              hasBackground={false}
              size="sm"
              leftIcon={SendIcon}
            >
              {t(`general.resend`)}
            </Button>
            <StyledButton
              disabled={Boolean(accepted || cancelled || expired)}
              id="cancel_button"
              value={inviteId}
              onClick={handleCancel}
              contentMode="tertiary"
              hasBackground={false}
              size="sm"
              leftIcon={CloseIcon}
            >
              {t(`general.cancel`)}
            </StyledButton>
          </StyledButtonsWrapper>
        </StyledOrgExpanderWrapper>
      </ContentSection>
    </ContentSection>
  )
}

export const InviteDetailsView = observer(InviteDetailsViewComponent)

function getNotiType(fetchingStage: string) {
  switch (fetchingStage) {
    case 'fetching':
      return 'warning'
    case 'resent':
      return 'success'
    case 'cancelled':
      return 'success'
  }
}

function getNotiText(fetchingStage: string, t: (key: string) => string) {
  switch (fetchingStage) {
    case 'fetching':
      return t(`general.fetching`)
    case 'resent':
      return t(`general.sent`)
    case 'cancelled':
      return t(`general.cancelled`)
  }
}

const StyledDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;

  @media ${breakpoint.mobile} {
    grid-template-columns: 1fr;
  }
`

const StyledOrgExpanderWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 16px;
  flex-wrap: nowrap;

  > :first-child {
    @media ${breakpoint.mobile} {
      width: 100%;
      order: 2;
    }
  }

  @media ${breakpoint.mobile} {
    flex-wrap: wrap;
    justify-content: end;
  }
`

const StyledButtonsWrapper = styled('div')(
  ({ theme: { spacing } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${spacing.space1};

    @media ${breakpoint.mobile} {
      order: 1;
      flex-direction: row;
      align-items: center;
    }
  `
)
