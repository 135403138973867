import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import styled from 'styled-components'

import { MainUserRadioGroup } from '../../components'
import { StoreContext } from '../../store'

const UserSectionComponent: React.FC = () => {
  const { inviteStore } = useContext(StoreContext)
  const { toggleUserRole, roleValues } = inviteStore

  return (
    <StyledSectionWrapper>
      <MainUserRadioGroup
        isMainUser={roleValues.includes('MainUser')}
        toggleUserRole={() => toggleUserRole('MainUser')}
      />
    </StyledSectionWrapper>
  )
}

export const UserSection = observer(UserSectionComponent)

const StyledSectionWrapper = styled.div`
  display: flex;
`
