import React, { useContext, useEffect } from 'react'

import { ID } from '../constants'
import { root } from '../index'
import { initLocales } from '../locales'
import { serviceAdapter } from '../service/serviceAdapter'
import { StoreContext } from '../store'
import { i18nInstance } from '../utils'
import { getCurrentBasePath } from '../utils/helpers'
import i18n from '../utils/i18n'
import { InboundMessage, InboundMessageType, OutboundMessageType, sendMessage } from '../utils/postMessage'
import ThemeWrapper from '../utils/ThemeWrapper'
import { FederatedFeatureProps } from '../utils/types/FederatedFeature'
import { Index } from './index'

initLocales()
serviceAdapter.setEndpointURL(window.location.hostname)

export const IndexWrapper: React.FC<FederatedFeatureProps> = (moduleFederationProps) => {
  const { authStore, userStore, searchStore, environmentStore } = useContext(StoreContext)
  const mFtokens = moduleFederationProps?.tokens

  /**
   * Keep support for legacy feature loader
   */
  const onMessage = (event: MessageEvent) => {
    if (event.origin !== window.location.origin) {
      return null
    }

    const data: InboundMessage = event.data

    if (!data || !data.type) {
      return null
    }

    if (data.sender !== ID.OMAPOSTI) {
      return null
    }

    switch (data.type) {
      case InboundMessageType.TOKENS:
        const tokens = data.payload

        if (tokens.encoded) {
          authStore.setAccessToken(tokens.encoded.accessToken)
          authStore.setIdToken(tokens.encoded.idToken)
          authStore.setRoleToken(tokens.encoded.roleToken)
          // TODO once auth store is loaded, fetch data for other stores

          environmentStore.fetchEnvironment()

          userStore.fetchUserData()
          // maybe this does not need to be loaded here, if there is a refactor with two links in the sidebar
          // also remove loading check
          searchStore.fetchUsersAndInvites()
          searchStore.fetchOrganisations()
        }
        break
      case InboundMessageType.UNMOUNT:
        // Use React's findDOMNode to get the root component to unmount.
        // This allows unmounting the feature root component even if the feature div is already removed from DOM by core.
        root?.unmount()
        break
    }
  }

  useEffect(() => {
    /**
     * Keep support for legacy feature loader
     */
    if (!Object.keys(moduleFederationProps).length) {
      window.addEventListener('message', onMessage)

      sendMessage({ type: OutboundMessageType.READY, payload: null })

      return () => {
        window.removeEventListener('message', onMessage)
        // Remove i18n listeners on unmount
        i18n.removeListeners()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (mFtokens) {
      i18nInstance.language = moduleFederationProps.locale
      const { accessToken, idToken, roleToken } = mFtokens

      authStore.setAccessToken(accessToken)
      authStore.setIdToken(idToken)
      authStore.setRoleToken(roleToken)
      authStore.setTokenRefreshCb(moduleFederationProps.requestTokenRefresh)

      environmentStore.fetchEnvironment()

      userStore.fetchUserData()
      // maybe this does not need to be loaded here, if there is a refactor with two links in the sidebar
      // also remove loading check
      searchStore.fetchUsersAndInvites()
      searchStore.fetchOrganisations()
    }

    return () => {
      authStore.reset()
    }
  }, [
    authStore,
    environmentStore,
    moduleFederationProps.locale,
    moduleFederationProps.requestTokenRefresh,
    searchStore,
    mFtokens,
    userStore,
  ])

  const renderIndex = () => (
    <ThemeWrapper>
      <Index basename={moduleFederationProps?.basename?.replace('organisations', '') || getCurrentBasePath()} />
    </ThemeWrapper>
  )

  if (!Object.keys(moduleFederationProps).length) {
    return renderIndex()
  }

  return <div id="oneaccount-orgadmin-front">{renderIndex()}</div>
}

export default IndexWrapper
