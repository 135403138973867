import { CheckmarkCircledIcon, Headline, ProfileIcon } from '@postidigital/posti-components/build/brand'
import { BrandTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { useParams } from 'react-router'
import styled, { css } from 'styled-components'

import { StoreContext } from '../../store'

const EditInfoHeaderComponent: React.FC = () => {
  const { userDetailsStore } = useContext(StoreContext)

  const params = useParams<{ orgid: string }>()

  const organisationId = params.orgid
  const organisationData = userDetailsStore.userOrganizations.find(
    (org) => org.organization.businessId === organisationId
  )

  const name = organisationData?.organization?.name
  const businessId = organisationData?.organization?.businessId
  const email = organisationData?.organizationUser?.email

  return (
    <StyledInfoBox>
      <StyledContentWrapper>
        <StyledHeadline as="h2" size="Six">{`${name} (${businessId})`}</StyledHeadline>
        {email && (
          <StyledText as="p">
            <StyledProfileIcon aria-hidden="true" />
            {email}
          </StyledText>
        )}
      </StyledContentWrapper>
      <StyledIconWrapper>
        <CheckmarkCircledIcon color={BrandTheme.color.surface.accent.highContrast.default} height="1.5rem" />
      </StyledIconWrapper>
    </StyledInfoBox>
  )
}

export const EditInfoHeader = observer(EditInfoHeaderComponent)

const StyledHeadline = styled(Headline)`
  margin-right: 8px;
  margin-bottom: 16px;
`

const StyledProfileIcon = styled(ProfileIcon)`
  margin-right: 8px;
`

const StyledInfoBox = styled('div')(
  ({
    theme: {
      brand: { color },
    },
  }) => css`
    width: 100%;
    background: transparent;
    border-radius: 4px;
    border: 2px solid ${color.surface.accent.highContrast.default};
    display: flex;
    flex-direction: row;
    margin-bottom: 40px;
  `
)

const StyledIconWrapper = styled('div')`
  margin: 16px 16px 16px auto;
`

const StyledContentWrapper = styled('div')`
  margin: 16px;
`

const StyledText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.Montserrat};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 143%;
  display: flex;
  align-items: center;
  color: #000000;
`
