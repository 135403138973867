import { Modal, ModalStateWrapper } from '@postidigital/posti-components'
import { Body, Button, CloseIcon } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { useCallback, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { StoreContext } from '../../store'
import { RemoveButton } from '..'

interface IProps {
  businessId: string
  accountId: string
  userEmail: string
}

const RemoveUserModalComponent: React.FC<IProps> = ({ businessId, accountId, userEmail }) => {
  const [t] = useTranslation()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { userDetailsStore } = useContext(StoreContext)

  const toggleModalOpen = useCallback(() => {
    setModalOpen((prevState) => !prevState)
  }, [])

  const handleDelete = useCallback(async () => {
    await userDetailsStore.removeOrganisation(businessId, accountId, userEmail)
    setModalOpen(false)
  }, [accountId, businessId, userDetailsStore, userEmail])

  return (
    <>
      <RemoveButton id={`remove_from_org_${businessId}_button`} onClick={toggleModalOpen} />
      <ModalStateWrapper open={modalOpen}>
        <Modal mobileMode="alert">
          <Modal.CloseButton type="button" aria-label="Close" onClick={toggleModalOpen} icon={CloseIcon} />
          <Modal.Content>
            <Modal.Header>
              <Modal.Title size="Five">{t(`removeModal.title`)}</Modal.Title>
            </Modal.Header>
            <Modal.MainContent>
              <Body size="Three">{t(`removeModal.content`)}</Body>
              <Modal.ButtonWrapper>
                <Button id="delete_modal" contentMode="primary" onClick={handleDelete}>
                  {t(`removeModal.removeButton`)}
                </Button>
                <Button contentMode="secondary" id="cancel_modal" onClick={toggleModalOpen}>
                  {t(`general.cancel`)}
                </Button>
              </Modal.ButtonWrapper>
            </Modal.MainContent>
          </Modal.Content>
        </Modal>
      </ModalStateWrapper>
    </>
  )
}

export const RemoveUserModal = observer(RemoveUserModalComponent)
