import { ArrowRightIcon, Button, PostcardIcon } from '@postidigital/posti-components/build/brand'
import { BrandTheme } from '@postidigital/posti-theme'
import { observer } from 'mobx-react-lite'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import styled, { css } from 'styled-components'

import { IInvite } from '../../store/dataModels/interfaces'
import { formatDate, getInviteState } from '../../utils'

interface IProps {
  invite: IInvite
}

export const InviteListItemComponent: React.FC<IProps> = ({ invite }) => {
  const { expired, email, expires, created, inviteId, cancelled, accepted } = invite
  const { t } = useTranslation()
  const navigate = useNavigate()

  const goToInvite = () => {
    navigate(`/invite-detail/${inviteId}`)
  }

  const getPostCardIconColor = (): string => {
    const pink = BrandTheme.color.surface.error.pressed
    const blue = BrandTheme.color.surface.success.pressed

    if (accepted) {
      return blue
    }

    if (cancelled) {
      return pink
    }

    if (expired) {
      return pink
    }

    return blue
  }

  return (
    <StyledTR onClick={goToInvite}>
      <StyledEmailText>
        <PostcardIcon color={getPostCardIconColor()} fontSize="14px" width={'24px'} height={'24px'} />
        <StyledSpan>{truncate(email, 30)}</StyledSpan>
      </StyledEmailText>
      <StyledCreatedText>{formatDate(created)}</StyledCreatedText>
      <StyledTimeExpired expired={expired}>
        {getInviteState(cancelled, expired, expires, accepted, t)}
      </StyledTimeExpired>

      <StyledArrowWrapper>
        <Button contentMode="tertiary" type="button" rightIcon={ArrowRightIcon} />
      </StyledArrowWrapper>
    </StyledTR>
  )
}

export const InviteListItem = observer(InviteListItemComponent)

const StyledTR = styled.tr(
  () => css`
    height: 48px;
    cursor: pointer;
  `
)

function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + '...' : str
}

const StyledSpan = styled.span(
  () => css`
    margin-left: 8px;
  `
)

const StyledEmailText = styled.td(
  ({ theme }) => css`
    font-family: ${theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    color: ${theme.color.neutralBlack};
    vertical-align: middle;
    word-break: break-all;
  `
)

const StyledCreatedText = styled.td(
  ({ theme }) => css`
    font-family: ${theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    color: ${theme.color.neutralNetworkGray};

    margin-left: 54px;
  `
)

const StyledTimeExpired = styled.td<{ expired: boolean }>(
  ({ theme, expired }) => css`
    font-family: ${theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    color: ${expired ? theme.color.neutralPassiveGray : theme.color.neutralNetworkGray};
  `
)

const StyledArrowWrapper = styled.td(
  ({ theme }) => css`
    font-family: ${theme.fontFamily.Montserrat};
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 143%;
    text-align: right;
  `
)
