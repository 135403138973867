import { Headline } from '@postidigital/posti-components/build/brand'
import { BrandTheme } from '@postidigital/posti-theme'
import React from 'react'

type Props = {
  children: string
  as?: `div` | `h1` | `h2` | `h3` | `h4`
}

export const MainTitle: React.FC<Props> = ({ children }) => {
  return (
    <Headline as="h1" size="Six" color={BrandTheme.color.text.title.primary}>
      {children}
    </Headline>
  )
}

export const SubTitle: React.FC<Props> = ({ children, as = 'h2' }) => {
  return (
    <Headline as={as} size="Seven">
      {children}
    </Headline>
  )
}
