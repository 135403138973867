import { Dropdown } from '@postidigital/posti-components/build/brand'
import React, { useCallback, useMemo, useState } from 'react'

import { IDropDownOption } from '../../../store/dataModels/interfaces'

const MySelect = (props: any) => {
  const [allSelected, setAllSelected] = useState<boolean>(false)
  const hasValue = useMemo(() => !!(Array.isArray(props.value) ? props.value.length : props.value), [props.value])
  const list = useMemo(() => {
    if (!props.grouped) {
      return props.options?.length > 1 ? [props.allOption, ...props.options] : props.options
    }

    const enabledCount = props.options?.reduce((count, group) => {
      if (!('options' in group)) {
        return count
      }

      return count + group.options.filter((opt: IDropDownOption) => !opt.isDisabled).length
    }, 0)

    return enabledCount > 0 ? [props.allOption, ...props.options] : props.options
  }, [props.allOption, props.grouped, props.options])

  const flatOptions = useMemo(() => {
    if (!props.grouped) {
      return list
    }

    const flatList = list?.reduce((accumulatedOptions, currentGroup) => {
      if (!('options' in currentGroup)) {
        return accumulatedOptions
      }

      const enabledOptions = currentGroup.options.filter(({ isDisabled }) => !isDisabled)

      return [...accumulatedOptions, ...enabledOptions]
    }, [])

    return flatList.length > 1 ? [props.allOption, ...flatList] : flatList
  }, [list, props.allOption, props.grouped])

  const selectChangeHandlerWithAllOption = useCallback(
    (selected: any[], event: any) => {
      // De-select options
      if (['deselect-option', 'remove-value'].includes(event.action)) {
        // cleared value can be unselected from the list or by its own X button
        const value = event?.option?.value || event?.removedValue?.value

        // De-select specific option and the "all" option
        if (value && value !== props.allOption.value) {
          return selected.filter((item) => item.value !== value && item.value !== props.allOption.value)
        }

        // De-select all options
        return []
      }

      const enabledOptions = flatOptions.filter((option) => !option.isDisabled)

      // Option "all" was clicked
      if (event.option?.value === props.allOption.value) {
        // "all" option was selected already
        if (selected.length === enabledOptions.length) {
          return []
        }

        return enabledOptions
      }

      // All options are selected, except for "all" option
      if (selected.length === enabledOptions.length - 1) {
        return [props.allOption, ...selected]
      }

      return selected
    },
    [flatOptions, props]
  )

  /**
   * Check if all option has to be selected
   */
  const evaluateSelectAll = useCallback(
    (selected: any[], event: any) => {
      setAllSelected(false)

      const selectedResult = selectChangeHandlerWithAllOption(selected, event)
      const containsAll = selectedResult.findIndex((option) => option.value === props.allOption.value)

      if (containsAll > -1) {
        setAllSelected(true)
      }

      return props.onChange(selectedResult)
    },
    [props, selectChangeHandlerWithAllOption]
  )

  /**
   * Add all option if required, but missing due to upstream change handlers
   */
  const selectedOptionsWIthAll = useMemo(() => {
    if (!props.value?.length) {
      return []
    }

    if (!allSelected) {
      const enabledOptions = flatOptions.filter((option) => !option.isDisabled)

      // All options are selected, except for "all" option
      if (props.value.length === enabledOptions.length - 1) {
        return [props.allOption, ...props.value]
      }

      return props.value
    }

    if (-1 < props.value?.findIndex((option) => option.value === props.allOption.value)) {
      return props.value
    }

    return [props.allOption, ...props.value]
  }, [allSelected, flatOptions, props.allOption, props.value])

  if (props.allowSelectAll) {
    if (props.grouped) {
      return (
        <Dropdown
          {...props}
          key={props.id}
          options={list}
          inputId={props.id}
          hasChevron={!hasValue}
          isClearable={hasValue}
          label={props.placeholder}
          onChange={evaluateSelectAll}
          value={selectedOptionsWIthAll}
        />
      )
    }

    return (
      <Dropdown
        {...props}
        key={props.id}
        options={list}
        hasChevron={!hasValue}
        isClearable={hasValue}
        label={props.placeholder}
        onChange={evaluateSelectAll}
        inputId={`input-${props.id}`}
        value={selectedOptionsWIthAll}
      />
    )
  }

  return (
    <Dropdown
      key={props.id}
      inputId={props.id}
      hasChevron={!hasValue}
      isClearable={hasValue}
      label={props.placeholder}
      {...props}
    />
  )
}

export default MySelect
