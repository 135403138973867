// https://reactjs.org/docs/error-boundaries.html
import React, { PropsWithChildren } from 'react'

import { ErrorComponent } from '../../components'

type State = {
  hasError: boolean
}

class ErrorBoundaryComponent extends React.Component<PropsWithChildren, State> {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch() {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorComponent error="general" />
    }

    return this.props.children
  }
}

export const ErrorBoundary = ErrorBoundaryComponent
