import { Input } from '@postidigital/posti-components/build/brand'
import { observer } from 'mobx-react-lite'
import React, { ChangeEventHandler, FormEventHandler, useCallback, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import styled, { css } from 'styled-components'

import { StoreContext } from '../../store'
import { ContentSection } from '../../style/layout'
import { TempFixLoadingButton } from '../buttons/buttons'
import { EmailItem } from './EmailItem'

const EmailsSectionComponent: React.FC = () => {
  const { t } = useTranslation()

  const { inviteStore } = useContext(StoreContext)
  const {
    email,
    setEmail,
    isValidEmail,
    emailList,
    parsedEmail,
    addEmail,
    isLoading,
    isEmailValidationConflict,
    clearPendingRequest,
  } = inviteStore
  const buttonDisabled = typeof email !== 'string' || email === '' || !isValidEmail

  const handleEmailInputChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      setEmail(event.currentTarget.value)
      // If we are showing current error clear pending request
      if (isEmailValidationConflict) {
        clearPendingRequest()
      }
    },
    [clearPendingRequest, isEmailValidationConflict, setEmail]
  )

  const addEmailToStore: FormEventHandler<HTMLFormElement> = useCallback(
    (event) => {
      event.preventDefault()
      event.nativeEvent.stopImmediatePropagation()

      if (isLoading) {
        return null
      }

      if (parsedEmail && isValidEmail) {
        addEmail(parsedEmail)
      }
    },
    [addEmail, isLoading, isValidEmail, parsedEmail]
  )

  const emailInputMessage = isEmailValidationConflict ? t('errors.invite_email_validation_conflict.title') : null

  return (
    <>
      <EmailInputForm id="add_invite_email_form" onSubmit={addEmailToStore}>
        <Input
          value={email}
          onChange={handleEmailInputChange}
          type="email"
          label={t(`general.email`)}
          id="email"
          lightBackground
          isInvalid={!isValidEmail || isEmailValidationConflict}
          message={emailInputMessage}
        />
        <TempFixLoadingButton
          type="submit"
          css
          contentMode="primary"
          size="sm"
          disabled={buttonDisabled}
          isLoading={isLoading}
        >
          {t(`general.add`)}
        </TempFixLoadingButton>
      </EmailInputForm>
      {!!emailList?.length && (
        <ContentSection top="sm" gap="sm">
          {emailList.map((email) => (
            <EmailItem key={email} email={email} />
          ))}
        </ContentSection>
      )}
    </>
  )
}

export const EmailsSection = observer(EmailsSectionComponent)

const EmailInputForm = styled('form')(
  ({
    theme: {
      brand: {
        spacing: { space8 },
      },
    },
  }) => css`
    display: flex;
    flex-wrap: nowrap;
    gap: ${space8}rem;
    align-items: center;
    justify-items: start;

    & :first-child {
      width: 100%;
      max-width: 400px;
    }
  `
)
