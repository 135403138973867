import { BrandTheme, XyzTheme } from '@postidigital/posti-theme'
import PropTypes from 'prop-types'
import React, { PropsWithChildren } from 'react'
import { createGlobalStyle, ThemeProvider } from 'styled-components'

// Set custom global styles here. Most of these should come from GlobalStyleNormalization
export const GlobalStyle = createGlobalStyle`
  * {
    -webkit-tap-highlight-color: transparent;
  }
`

export const combinedTheme = { ...XyzTheme, brand: BrandTheme }

const ThemeWrapper: React.FC<PropsWithChildren> = ({ children }) => (
  <ThemeProvider theme={combinedTheme}>
    <>{children}</>
  </ThemeProvider>
)

ThemeWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

export default ThemeWrapper
